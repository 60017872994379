import React, { useContext, useEffect, useState } from "react";
import { Header, MidBlock } from "./styles";
import { useStack } from "../../../hooks/useStaking";
import {
  Button,
  Card,
  Divider,
  Group,
  Image,
  Modal,
  Progress,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { InfoCircle } from "tabler-icons-react";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-reactjs";
import { useSPStack } from "../../../hooks/useSPStaking";
import axios from "axios";
import { StackContext } from "../../../contexts/Staking/sp-provider/Context";

const DABIslandStacking = () => {
  const { globalInfo } = useStack();
  const { jungle } = useSPStack();

  const [stats, setStats] = useState({
    single: 0,
    pairs: 0,
    apes: 0,
    tigers: 0,
  });
  const [opened, setOpened] = useState(false);
  const numApeStaked =
    (globalInfo?.totalStakedApeCount.toNumber() || 0) +
    Math.ceil((jungle?.animalsStaked?.toNumber() || 0) / 2);
  const numTigerStaked =
    (globalInfo?.totalStakedTigerCount.toNumber() || 0) +
    Math.floor((jungle?.animalsStaked?.toNumber() || 0) / 2);

  const percentageOfApeStaked = parseFloat(
    ((numApeStaked / 5000) * 100).toFixed(2)
  );
  const percentageofTigerStaked = parseFloat(
    ((numTigerStaked / 5000) * 100).toFixed(2)
  );

  const totalPercent = (
    ((numApeStaked + numTigerStaked) / 10000) *
    100
  ).toFixed(2);

  const [modal] = useSinglePrismicDocument("staking_modal");

  const getStakingStats = async () => {
    const stats = await axios
      .get("https://basc-prod.herokuapp.com/api/staking/stats")
      .then((result: any) => result.data);
    setStats(stats);
  };

  useEffect(() => {
    getStakingStats();
  }, []);

  return (
    <>
      <Header>
        <MidBlock>
          <div className="text-center ginchiest text-6xl mb-5 text-white">
            Dab Island Staking
          </div>
        </MidBlock>
      </Header>
      <Card
        withBorder
        shadow="sm"
        p="md"
        sx={(theme) => ({
          backgroundColor: "transparent",
          color: "white",
          border: "5px solid white",
          borderRadius: "0",
        })}
      >
        <Group className="md:flex-nowrap flex-wrap">
          <Image
            className="md:h-[400px] md:w-[400px]"
            src="/main.jpg"
            alt="Pair staking"
            radius="sm"
            style={{ maxWidth: "400px" }}
          />

          <Divider
            className="h-[400px] self-center text-white hidden md:block"
            orientation="vertical"
          />

          <Stack
            spacing="xl"
            className="md:h-[400px] w-[calc(100% - 400px)] grow"
          >
            <Group position="apart">
              <Title>Staking Stats</Title>
              <Button
                onClick={() => setOpened(true)}
                variant="subtle"
                className="hover:bg-transparent hover:text-orange-600"
              >
                <InfoCircle size={25} />
              </Button>
            </Group>

            <Progress
              className="w-full"
              color="orange"
              value={parseInt(totalPercent) || 1}
              striped
              animate
              size="xl"
            />
            <Group position="apart" noWrap>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  10,000
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Collection Total NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  Total #
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {numApeStaked + numTigerStaked}
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Collection Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  # Staked
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {totalPercent}%
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Collection % Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  % Staked
                </Text>
              </div>
            </Group>
            {/* <Progress
              color="orange"
              value={stats.pairs / 500 || 1}
              striped
              animate
              size="xl"
            /> */}
            {/* <Group position="apart" noWrap>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">5,000</Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Apes Total
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  Total # BA
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {stats.pairs}
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                Apes Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  # BA Staked
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {stats.pairs / 500}%
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                Apes % Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  % BA Staked
                </Text>
              </div>
            </Group> */}
            <Progress
              color="orange"
              value={percentageOfApeStaked || 1}
              striped
              animate
              size="xl"
            />
            <Group position="apart" noWrap>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">5,000</Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Total NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  Total # BA
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {numApeStaked}
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Baby Apes Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  # BA Staked
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {percentageOfApeStaked}%
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs hidden md:block">
                  Baby Apes % Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  % BA Staked
                </Text>
              </div>
            </Group>
            <Progress
              color="orange"
              value={percentageOfApeStaked || 1}
              striped
              animate
              size="xl"
            />
            <Group position="apart" noWrap>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">5,000</Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Total NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  Total # BT
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {numTigerStaked}
                </Text>
                <Text className="text-gray-500 md:text-sm hidden md:block">
                  Baby Tigers Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  # BT Staked
                </Text>
              </div>
              <div className="text-center">
                <Text className="font-semibold md:text-2xl text-xs">
                  {percentageofTigerStaked}%
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs hidden md:block">
                  Baby Tigers % Staked NFTs
                </Text>
                <Text className="text-gray-500 md:text-sm text-xs md:hidden">
                  % BT Staked
                </Text>
              </div>
            </Group>
          </Stack>
        </Group>
      </Card>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={3}>
            {modal?.data?.title
              ? modal?.data?.title
              : "About $DAB Island Staking"}
          </Title>
        }
        centered
      >
        <div>
          <RichText render={modal?.data?.body} />
        </div>
        <Group grow>
          <Button onClick={() => setOpened(false)}>OK</Button>
        </Group>
      </Modal>
    </>
  );
};

export default DABIslandStacking;
