import * as anchor from "@project-serum/anchor";
import { WalletModalProvider } from "@solana/wallet-adapter-ant-design";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import "antd/dist/antd.min.css";
import { useEffect, useMemo, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StakingProvider } from "./contexts/Staking/Provider";
import Staking from "./pages/Staking";
import i18n from "./translation";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faTimes } from "@fortawesome/free-solid-svg-icons";
import { AppShell, MantineProvider } from "@mantine/core";
import theme from "./theme";
import { PrismicProvider } from "@prismicio/react";
import { client } from "./prismic";
import { SPStakingProvider } from "./contexts/Staking/sp-provider/Provider";
import Nav from "./common/Nav/Nav";
import Footer from "./common/Footer";
library.add(fas, faTimes);
require("@solana/wallet-adapter-ant-design/styles.css");
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

export const App = () => {
  const rpcHost =
    "https://skilled-compatible-putty.solana-mainnet.quiknode.pro/";
  // const rpcHost = anchor.web3.clusterApiUrl('mainnet-beta');
  const connection = new anchor.web3.Connection(
    rpcHost ? rpcHost : anchor.web3.clusterApiUrl("mainnet-beta"),
    { confirmTransactionInitialTimeout: 60000 }
  );

  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <div className="App">
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                  <PrismicProvider client={client}>
                    <AppShell padding={0} navbar={<Nav />} footer={<Footer />}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <SPStakingProvider connection={connection}>
                              <StakingProvider connection={connection}>
                                <Staking />
                              </StakingProvider>
                            </SPStakingProvider>
                          }
                        />
                      </Routes>
                    </AppShell>
                  </PrismicProvider>
                </WalletModalProvider>
              </WalletProvider>
            </ConnectionProvider>
          </I18nextProvider>
        </BrowserRouter>
      </div>
    </MantineProvider>
  );
};
