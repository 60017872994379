import styled, { keyframes } from "styled-components/macro";

export const Header = styled("div")`
  margin-top: 40px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 620px) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;

export const Block = styled("div")`
  flex: 1;
`;

export const MidBlock = styled("div")`
 flex: 1;
 justify-content: center;
 align-items: center ;
`;

export const Title = styled("p")`
  font-weight: 500;
  font-size: 47px;
  text-align: center;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 30px;
  }

  @media (max-width: 425px) {
    font-size: 22px;
  }
`;

export const Button = styled("button")`
  background: #4e43ce;
  border-radius: 3px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
`;

export const Collection = styled("div")`
  width: 100%;
  padding: 27px 130px;
  border-radius: 15px;
  margin-top: 10px;
  background: rgba(255, 153, 33, 0.25);
  
  @media (max-width: 960px) {
    padding: 15px 30px;
  }

  @media (max-width: 620px) {
    margin-top: 0px;
  }

  @media (max-width: 500px) {
    padding: 15px;
  }
`;

export const Row = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
`;

export const MiddleRow = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  padding: 15px 0;
`;

export const Col = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;

export const TableHeading = styled("p")`
  font-weight: 600;
  font-size: 24px;
  margin: 0;

  @media (max-width: 1060px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }

  @media (max-width: 520px) {
    font-size: 12px;
  }

  @media (max-width: 375px) {
    font-size: 10px;
  }
`;

export const TableData = styled("p")`
  font-weight: 400;
  font-size: 18px;
  margin: 0;

  @media (max-width: 1060px) {
    font-size: 16px;
  }

  @media (max-width: 700px) {
    font-size: 12px;
  }

  @media (max-width: 520px) {
    font-size: 10px;
  }

  @media (max-width: 375px) {
    font-size: 8px;
  }
`;
