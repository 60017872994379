import React, { useMemo } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback, useEffect, useState } from "react";
import { useStack } from "../../../hooks/useStaking";
import NftCard from "../NftCard";
import { CollectionRoot, GridContainer, PairGridContainer } from "./styles";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // import plugin
import "dayjs/locale/en";
import NftPairCard from "../NftPairCard";
import {
  Tabs,
  Button,
  Group,
  Divider,
  Text,
  Space,
  Title,
  Image,
  Stack,
  Skeleton,
  Modal,
  Checkbox,
} from "@mantine/core";
import Header from "../../../common/Header";

dayjs.extend(relativeTime); // use plugin
dayjs.locale("en"); // use locale

const notFoundLabels = [
  "No NFTs found in your wallet",
  "No matching pairs found in your wallet",
  "No matching pairs staked... yet ;)",
  "No NFTs staked... yet ;)",
];

const Collections = () => {
  const {
    animals,
    stakedAnimals,
    pairAnimals,
    stakedPairAnimals,
    stakeAnimal,
    unstakeAnimal,
    claimAllStakingRewards,
    stakePairAnimal,
    unstakePairAnimal,
    claimAllPairStakingRewards,
    animalsStatus,
    stakedAnimalsStatus,
    avaliableStakedAnimals,
    setAvaliableStakedAnimals,
    avaliableStakedPairAnimals,
    setAvaliableStakedPairAnimals,
    refetchData,
  } = useStack();
  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(false);
  const wallet = useWallet();
  const [opened, setOpened] = useState(false);
  const [checked, setChecked] = useState(false);

  const walletDisconnected = !wallet.connected || wallet.disconnecting;

  useEffect(() => {
    const getAvaliableStakedAnimals = async () => {
      await setAvaliableStakedAnimals();
    };
    if (stakedAnimals.length > 0) getAvaliableStakedAnimals();
    const getAvaliableStakedPairAnimals = async () => {
      await setAvaliableStakedPairAnimals();
    };
    if (stakedPairAnimals.length > 0) getAvaliableStakedPairAnimals();
  }, [stakedAnimals, stakedPairAnimals]);

  const refetch = async () => {
    setLoading(true);
    await refetchData();
    setLoading(false);
  };

  const handleActiveTab = (key: number) => {
    let tabToSet = key;
    // if (key === 4 && animalsReady && !animals.length && animalsStatus.loadingEnd && !stakedAnimals.length) tabToSet = 2;
    // if (key === 3 && animalsReady && !pairAnimals.length && animalsStatus.loadingEnd && !stakedPairAnimals.length) tabToSet = 1;
    setActive(tabToSet);
  };

  const handleDismiss: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setChecked(event.currentTarget.checked);
    localStorage.setItem("stake-all-modal-dismiss", "true");
  };

  const handleClaimAll = useCallback(async () => {
    try {
      await claimAllStakingRewards();
      const date = new Date();
      const _avaliableStakedAnimals = avaliableStakedAnimals.map((animal) => {
        return {
          ...animal,
          lastClaim: date,
        };
      });
      setAvaliableStakedAnimals(_avaliableStakedAnimals);
    } catch (e) {
      console.log(e);
    }
  }, [
    claimAllStakingRewards,
    avaliableStakedAnimals,
    setAvaliableStakedAnimals,
  ]);

  const handleUnstakeAll = useCallback(async () => {
    try {
      await unstakeAnimal(avaliableStakedAnimals, true);
    } catch (e) {
      console.log(e);
    }
  }, [unstakeAnimal, avaliableStakedAnimals]);

  const handleStakeAll = useCallback(async () => {
    if (animals.length > 0) {
      if (localStorage.getItem("stake-all-modal-dismiss") !== "true")
        setOpened(true);
      try {
        await stakeAnimal(animals, true);
      } catch (e) {
        console.log(e);
      }
    }
  }, [stakeAnimal, animals.length]);

  const handleClaimAllPair = useCallback(async () => {
    try {
      await claimAllPairStakingRewards();
      const date = new Date();
      const _avaliableStakedPairAnimals = avaliableStakedPairAnimals.map(
        (animal) => {
          return {
            ...animal,
            lastClaim: date,
          };
        }
      );
      setAvaliableStakedPairAnimals(_avaliableStakedPairAnimals);
    } catch (e) {
      console.log(e);
    }
  }, [
    claimAllStakingRewards,
    avaliableStakedPairAnimals,
    setAvaliableStakedPairAnimals,
  ]);

  const handleStakeAllPair = useCallback(async () => {
    if (pairAnimals.length > 0) {
      try {
        await stakePairAnimal(pairAnimals, true);
      } catch (e) {
        console.log(e);
      }
    }
  }, [stakePairAnimal, pairAnimals.length]);

  const handleUnstakeAllPair = useCallback(async () => {
    try {
      await unstakePairAnimal(avaliableStakedPairAnimals, true);
    } catch (e) {
      console.log(e);
    }
  }, [unstakePairAnimal, avaliableStakedPairAnimals]);

  const notConnectedNotFound = (
    <Stack>
      <Title order={3}>
        {walletDisconnected ? "Connect your wallet" : notFoundLabels[active]}
      </Title>
      {wallet.connected && (
        <Button
          component="a"
          href="https://www.magiceden.io/marketplace/baby_ape_social_club"
          target="_blank"
          rel="noreferrer"
          className="mt-2 w-fit"
        >
          Buy Now
        </Button>
      )}
    </Stack>
  );

  const pairsLabel = (
    <Group position="apart">
      <Group noWrap spacing="xs">
        <Image width={20} src="/ape.png" />
        <Image width={20} src="/tiger.png" />
      </Group>
      <Text className="md:block hidden">Unstaked Matching</Text>
    </Group>
  );

  const pairsStakedLabel = (
    <Group position="apart" noWrap>
      <Group noWrap spacing="xs">
        <Image width={20} src="/jungle.png" />
        <Image width={20} src="/ape.png" />
        <Image width={20} src="/tiger.png" />
      </Group>
      <Text className="md:block hidden whitespace-nowrap">Staked Matching</Text>
    </Group>
  );

  const individualLabel = (
    <Group position="apart" noWrap>
      <Group>
        <Image width={20} src="/ape.png" />
      </Group>
      <Text className="md:block hidden">Unstaked Individual</Text>
    </Group>
  );

  const stakedIndLabel = (
    <Group position="apart" noWrap>
      <Image width={20} src="/jungle.png" />
      <Image width={20} src="/ape.png" />
      <Text className="md:block hidden">Staked Individual</Text>
    </Group>
  );

  return (
    <>
      <CollectionRoot>
        <Group position="apart" className="my-2">
          <Header />
          <Group>
            <Button loading={loading} variant="light" onClick={refetch}>
              Refresh Data
            </Button>
            {active === 0 || active === 1 ? (
              <Button
                variant="outline"
                disabled={
                  walletDisconnected ||
                  (active === 0 && animals.length === 0) ||
                  (active === 1 && pairAnimals.length === 0)
                }
                onClick={active === 1 ? handleStakeAllPair : handleStakeAll}
              >
                Stake All
              </Button>
            ) : (
              <Button
                variant="outline"
                disabled={
                  walletDisconnected ||
                  (active === 2 && stakedPairAnimals.length === 0) ||
                  (active === 3 && stakedAnimals.length === 0)
                }
                onClick={active === 3 ? handleUnstakeAll : handleUnstakeAllPair}
              >
                Unstake All
              </Button>
            )}
            <Button
              disabled={
                walletDisconnected ||
                active === 0 ||
                active === 1 ||
                (active === 2 && stakedPairAnimals.length === 0) ||
                (active === 3 && stakedAnimals.length === 0)
              }
              onClick={active === 2 ? handleClaimAllPair : handleClaimAll}
            >
              Claim All
            </Button>
          </Group>
        </Group>
        {/* Nft collection Tab */}
        <Tabs
          className="mt-6"
          grow
          variant="unstyled"
          initialTab={1}
          styles={(theme) => ({
            tabControl: {
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.white,
              color:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[0]
                  : theme.colors.gray[9],
              border: `1px solid ${
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[4]
              }`,
              fontSize: theme.fontSizes.md,
              padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,

              "&:not(:first-of-type)": {
                borderLeft: 0,
              },

              "&:first-of-type": {
                borderTopLeftRadius: theme.radius.md,
                borderBottomLeftRadius: theme.radius.md,
              },

              "&:last-of-type": {
                borderTopRightRadius: theme.radius.md,
                borderBottomRightRadius: theme.radius.md,
              },
            },

            tabActive: {
              backgroundColor: theme.colors.orange[7],
              borderColor: theme.colors.orange[7],
              color: theme.white,
            },
          })}
          onTabChange={(tabIndex) => handleActiveTab(tabIndex)}
        >
          <Tabs.Tab label={individualLabel}>
            {walletDisconnected ||
            (!animals.length && animalsStatus.loadingEnd) ? (
              notConnectedNotFound
            ) : (
              <>
                <Divider className="mb-3 mt-1" />
                <GridContainer>
                  {animalsStatus.loading
                    ? [...Array(6)].map((arr, i) => (
                        <Skeleton key={i} height={457} width={317} />
                      ))
                    : animals?.map((dolphin) => (
                        <NftCard
                          key={dolphin.uriData.image}
                          isStaked={false}
                          token={dolphin}
                        />
                      ))}
                </GridContainer>
              </>
            )}
          </Tabs.Tab>
          <Tabs.Tab label={pairsLabel}>
            {walletDisconnected ||
            (!pairAnimals.length && animalsStatus.loadingEnd) ? (
              <>{notConnectedNotFound}</>
            ) : (
              <>
                <PairGridContainer>
                  {animalsStatus.loading
                    ? [...Array(6)].map((arr, i) => (
                        <Skeleton key={i} height={457} width={317} />
                      ))
                    : pairAnimals?.map((dolphin) => (
                        <NftPairCard
                          key={dolphin.apeUriData.image}
                          isStaked={false}
                          token={dolphin}
                        />
                      ))}
                  {pairAnimals.length === 0 && notConnectedNotFound}
                </PairGridContainer>
              </>
            )}
          </Tabs.Tab>
          <Tabs.Tab label={pairsStakedLabel}>
            {walletDisconnected ||
            (!stakedPairAnimals.length && stakedAnimalsStatus.loadingEnd) ? (
              <>{notConnectedNotFound}</>
            ) : (
              <>
                <PairGridContainer>
                  {avaliableStakedPairAnimals.length
                    ? avaliableStakedPairAnimals?.map((dolphin) => (
                        <NftPairCard
                          key={dolphin.apeUriData.image}
                          isStaked={true}
                          token={dolphin}
                        />
                      ))
                    : stakedAnimalsStatus.loading
                    ? [...Array(6)].map((arr, i) => (
                        <Skeleton key={i} height={457} width={317} />
                      ))
                    : ""}
                </PairGridContainer>
              </>
            )}
          </Tabs.Tab>
          <Tabs.Tab label={stakedIndLabel}>
            {walletDisconnected ||
            (!stakedAnimals.length && stakedAnimalsStatus.loadingEnd) ? (
              <>{notConnectedNotFound}</>
            ) : (
              <>
                <GridContainer>
                  {stakedAnimals.length
                    ? stakedAnimals?.map((dolphin) => (
                        <NftCard
                          key={dolphin.uriData.image}
                          isStaked={true}
                          token={dolphin}
                        />
                      ))
                    : stakedAnimalsStatus.loading
                    ? [...Array(3)].map((arr, i) => (
                        <Skeleton key={i} height={457} width={317} />
                      ))
                    : ""}
                </GridContainer>
              </>
            )}
          </Tabs.Tab>
        </Tabs>
        <Space h="xl" />
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          title={<Title order={3}>Stake All</Title>}
          size={500}
          centered
        >
          <Group direction="column">
            <img className="w-full object-cover object-bottom" height={250} src="/basc.jpeg" />

            <Group direction="column" position="apart">
              <Text>
                <span className="font-bold">Important!</span> if you have 
                <span className="font-bold text-yellow-500"> matching pairs</span> please stake them first before staking all
                individual Apes & Tigers. If you stake all individuals first,
                your pairs will be staked as individual and will <span className="font-bold text-red-600">NOT</span> get the
                1.25x multiplier.
              </Text>
              <Text>
                Please wait a moment for your data to update you may need to
                wait a few minutes and then click "Refresh Data"
              </Text>
              <Checkbox
                label="Dismiss modal for this session"
                checked={checked}
                onChange={handleDismiss}
              />
              <Button
                onClick={() => setOpened(false)}
                className="w-full mt-auto"
              >
                OK
              </Button>
            </Group>
          </Group>
        </Modal>
      </CollectionRoot>
    </>
  );
};

export default Collections;
