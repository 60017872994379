import {
  Button,
  Divider,
  Group,
  Image,
  Modal,
  Navbar,
  Stack,
  Title,
} from "@mantine/core";
import { PrismicRichText, useSinglePrismicDocument } from "@prismicio/react";
import { useWallet } from "@solana/wallet-adapter-react";

import {
  IconAlien,
  IconHome2,
  IconInfoCircle,
  IconOld,
  IconStack2,
} from "@tabler/icons";

import { useState } from "react";
import WalletConnect from "./WalletConnect";

export default function Nav() {
  const wallet = useWallet();
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);

  const [document] = useSinglePrismicDocument("v3_modal");
  const modalData = document?.data;

  const styles = {
    root: {
      ":hover:": {
        backgroundColor: "#C1C2C5 !important",
      },
    },
    inner: {
      justifyContent: "flex-start",
      ":hover:": {
        background: "#C1C2C5",
      },
    },
    label: {
      color: "#C1C2C5",
      fontWeight: 500,
    },
    leftIcon: {
      color: "#C1C2C5",
    },
  };

  return (
    <Navbar
      id="navbar"
      p="md"
      hiddenBreakpoint="md"
      width={{ sm: 80, lg: 250 }}
      style={{
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Navbar.Section>
        <Group position="center">
          <Image width={160} src="logo.png" />
        </Group>
      </Navbar.Section>

      <Navbar.Section>
        <Stack my="md">
          <Divider />
          <Button
            styles={styles}
            variant="subtle"
            component="a"
            href="https://www.babyapesocialclub.com/"
            leftIcon={<IconHome2 size={16} stroke={1.5} />}
          >
            Home
          </Button>
          <Button
            styles={styles}
            variant="subtle"
            leftIcon={<IconAlien size={16} stroke={1.5} />}
            component="a"
            href="https://stake.babyapesocialclub.com/"
          >
            Staking (incl. E2)
          </Button>
          <Button
            styles={{
              ...styles,
              root: {
                ...styles.root,
                backgroundColor: "rgba(232, 89, 12, 0.2)",
              },
            }}
            variant="subtle"
            component="a"
            href="https://stakingv2.babyapesocialclub.com/"
            leftIcon={<IconStack2 size={16} stroke={1.5} />}
          >
            V2 Staking
          </Button>
          <Button
            styles={styles}
            variant="subtle"
            component="a"
            href="https://staking.babyapesocialclub.com/"
            leftIcon={<IconOld size={16} stroke={1.5} />}
          >
            Legacy Staking
          </Button>
        </Stack>
      </Navbar.Section>
      <Divider />

      <Navbar.Section mt={"auto"}>
        {wallet.connected && (
          <Stack>
            <WalletConnect />
          </Stack>
        )}
      </Navbar.Section>
      {modalData && (
        <Modal
          size={600}
          opened={opened}
          onClose={() => setOpened(false)}
          title={<Title order={2}>{modalData.title}</Title>}
        >
          <div>
            <PrismicRichText field={modalData.description} />
            <Button className="float-right" onClick={() => setOpened(false)}>
              Ok
            </Button>
          </div>
        </Modal>
      )}
    </Navbar>
  );
}
