import Header from "../../common/Header";
import DABIslandStacking from "../../components/staking//DABIslandStacking";
import Collections from "../../components/staking/Collections";
import RpcDropdown from "../../components/staking/RpcDropdown";
import Navbar from "../../common/Navbar";
import Footer from "../../common/Footer";
import { StakingWrapper } from "./styles";
import Protected from "../Protected";
import { useState } from "react";
import { Container } from "@mantine/core";

const Staking = () => {
  const [isAuth, setAuth] = useState(false);
  return (
    <Container size={1300} pb={20} px={40}>
      <div className="page-height min-h-screen md:min-h-0">
        <DABIslandStacking />
        <Collections />
      </div>
      {/* {!isAuth && <Protected setAuth={(value: boolean) => setAuth(value)} />} */}
    </Container>
  );
};

export default Staking;
