import { Button } from '@mantine/core';
import { useWalletModal } from '@solana/wallet-adapter-ant-design';
import { useWallet } from '@solana/wallet-adapter-react';
import { Row } from 'antd';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSPStack } from '../../hooks/useSPStaking';
import { useStack } from '../../hooks/useStaking';
import useWindowSize from '../../hooks/useWindowSize';
import {
  CustomNavLinkSmall,
  HeaderSection,
  NavbarLinkRoot,
  Span,
  WalletInfoText,
  DisconnectButton,
  ProgressBar,
  Progress,
  ProgressTitle,
  StakedAnimalsWrapper,
  ConnectButton
} from './styles';

const Header = ({ t }: any) => {
  const { globalInfo } = useStack();
  const numApeStaked = globalInfo?.totalStakedApeCount.toNumber() || 0;
  const numTigerStaked = globalInfo?.totalStakedTigerCount.toNumber() || 0;
  const percentageStaked = parseFloat((((numApeStaked + numTigerStaked) / 10000 * 100)).toFixed(2));
  const { isMobile, isTabPort } = useWindowSize()

  const MenuItem = () => {
    const { setVisible: setOpenWalletModal } = useWalletModal();
    const { disconnect, connected, publicKey } = useWallet();

    return (
      <NavbarLinkRoot>
        <Button
          variant="white"
          onClick={() =>
            window.open(
              'https://www.magiceden.io/marketplace/baby_ape_social_club'
            )
          }
        >
          <Span>{t('Buy')}</Span>
        </Button>

        {connected ? (
          <>
            <Button variant="light" onClick={() => disconnect()}>Disconnect</Button>
            <WalletInfoText>
              <svg
                id='Layer_1'
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 25 25'
              >
                <title>Wallet</title>
                <path
                  id='Wallet'
                  d='M20,15a1,1,0,1,1-1-1A1,1,0,0,1,20,15Zm5-1.75v3.5A1.23,1.23,0,0,1,24,18v3.8A1.25,1.25,0,0,1,22.75,23H2.5A2.5,2.5,0,0,1,0,20.5V5.5A2.5,2.5,0,0,1,2.5,3H20.75A1.25,1.25,0,0,1,22,4.25V7h.75A1.25,1.25,0,0,1,24,8.25v3.8A1.23,1.23,0,0,1,25,13.25ZM1,5.5a1.46,1.46,0,0,0,.46,1.08A1.49,1.49,0,0,0,2.5,7H21V6H3V5H21V4.25A.25.25,0,0,0,20.75,4H2.5A1.5,1.5,0,0,0,1,5.5ZM23,18H19a3,3,0,1,1,0-6h4V8.25A.25.25,0,0,0,22.75,8H2.5A2.47,2.47,0,0,1,1,7.48v13A1.5,1.5,0,0,0,2.5,22H22.75a.25.25,0,0,0,.25-.25Zm1-4.75a.25.25,0,0,0-.25-.25H19a2,2,0,0,0-2,2.34A2.08,2.08,0,0,0,19.11,17h4.64a.25.25,0,0,0,.25-.25Z'
                />
              </svg>{' '}
              {publicKey?.toString().substring(0, 4)}...
              {publicKey
                ?.toString()
                .substring(
                  publicKey?.toString().length - 2,
                  publicKey?.toString().length
                )}
            </WalletInfoText>
          </>
        ) : (
          <Button onClick={() => setOpenWalletModal(true)}>Connect</Button>
        )}
      </NavbarLinkRoot>
    );
  };

  return (
    <MenuItem />
  );
};

export default withTranslation()(Header);
