import { Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Animal } from "../../../contexts/Staking/types";
import { useStack } from "../../../hooks/useStaking";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // import plugin
import "dayjs/locale/en";
import { useConnection } from "@solana/wallet-adapter-react";
import {
  Card,
  Title,
  Button,
  Image,
  Stack,
  Group,
  createStyles,
  useMantineTheme,
  SimpleGrid,
  Modal,
  Text,
  Checkbox,
} from "@mantine/core";

dayjs.extend(relativeTime); // use plugin
dayjs.locale("en"); // use locale

interface Props {
  token: Animal;
  isStaked?: boolean;
}

const NftCard = ({ token, isStaked }: Props) => {
  const {
    avaliableStakedAnimals,
    setAvaliableStakedAnimals,
    getPendingStakingRewards,
    getTimestamp,
    stakeAnimal,
    unstakeAnimal,
    claimStakingRewards,
  } = useStack();

  const [stakingPeriod, setStakingPeriod] = useState<Date>(new Date());
  const [redeemable, setRedeemable] = useState<number>(0);
  const [opened, setOpened] = useState(false);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);
  const connection = useConnection();

  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        setStakingPeriod(new Date());
      }
    }, 500);
    return () => clearInterval(interval);
  }, [token, getPendingStakingRewards, setStakingPeriod]);

  // handle Stake Nft
  const handleStake = useCallback(async () => {
    if (!token) return;
    setType("stake");
    try {
      if (localStorage.getItem("indv-modal-dismiss") !== "true")
        setOpened(true);
      await stakeAnimal(token);
    } catch (e) {
      console.log(e);
    }
  }, [token, stakeAnimal]);

  // handle Unstake NFt
  const handleUnstake = useCallback(async () => {
    if (!token) return;
    setType("unstake");
    try {
      if (localStorage.getItem("unst-modal-dismiss") !== "true")setOpened(true);
      await unstakeAnimal(token);
    } catch (e) {
      console.log(e);
    }
  }, [token, unstakeAnimal]);

  // handle Claim Earning
  const handleClaim = useCallback(async () => {
    if (!token) return;
    try {
      await claimStakingRewards(token);
      const now = await getTimestamp();
      const result = avaliableStakedAnimals.map((animal) => {
        if (animal.mint === token.mint) {
          return {
            ...token,
            lastClaim: new Date(now * 1000),
          };
        } else return animal;
      });
      setAvaliableStakedAnimals(result);
    } catch (e) {
      console.log(e);
      console.log("Claim Failed.");
    }
  }, [
    token,
    claimStakingRewards,
    avaliableStakedAnimals,
    setAvaliableStakedAnimals,
  ]);

  // pending rewords
  useEffect(() => {
    if (!token?.lastClaim) return;
    if (token?.lastClaim && isStaked) {
      let redeem = 0;
      const timeLog = async () => {
        const timestamp = await getTimestamp();
        redeem = timestamp;
      };
      timeLog();
      return () => {
        if (redeem)
          setRedeemable(
            getPendingStakingRewards(token, new Date(redeem * 1000))
          );
      };
    }
  }, [token, stakingPeriod, getPendingStakingRewards, isStaked]);

  const handleDismiss: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setChecked(event.currentTarget.checked);
    localStorage.setItem(
      type === "stake" ? "indv-modal-dismiss" : "unst-modal-dismiss",
      "true"
    );
  };

  return (
    <>
      <Card
        shadow="sm"
        p="lg"
        withBorder
        sx={(theme) => ({
          backgroundColor: theme.other.cardBackgroundColor,
          color: "white",
        })}
      >
        {token.uriData.image ? (
          <Image src={token.uriData.image} alt="" />
        ) : (
          <Skeleton.Avatar active shape="square" size={300} />
        )}

        <Title className="my-3" order={3}>
          {token.uriData.name}
        </Title>
        <SimpleGrid cols={2}>
          <span>Rewards Per Day</span>{" "}
          <span>
            {(token?.emissionsPerDay || 0) / 10 ** 9}
            <span style={{ fontSize: 16 }}> $DAB</span>
          </span>
        </SimpleGrid>
        {token?.lastClaim && isStaked && (
          <SimpleGrid cols={2}>
            <span>Pending Rewards</span>{" "}
            <span>{redeemable.toPrecision(5)}</span>
          </SimpleGrid>
        )}

        <Group grow className="my-3" noWrap>
          {token?.lastClaim && isStaked ? (
            <>
              {/* {!isLocked && (<> */}
              <Button
                sx={() => ({
                  color: "white",
                  borderColor: "white",
                })}
                variant="outline"
                onClick={handleClaim}
              >
                Claim Rewards
              </Button>
              {/* </>)} */}
              <Button
                sx={() => ({
                  color: "white",
                  borderColor: "white",
                })}
                variant="outline"
                onClick={handleUnstake}
              >
                Unstake NFT
              </Button>
            </>
          ) : (
            <Button
              sx={() => ({
                color: "white",
                borderColor: "white",
              })}
              variant="outline"
              onClick={handleStake}
            >
              Stake NFT
            </Button>
          )}
        </Group>
      </Card>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={3}>
            NFT is {type === "stake" ? "Staking" : "Unstaking"}!
          </Title>
        }
        size={620}
        centered
      >
        <Group className="md:flex-nowrap">
          <Image width={250} src={token.uriData.image} />
          <Group direction="column" position="apart" className="h-[250px]">
            <Text>
              Your NFT is {type === "stake" ? "staking" : "unstaking"}. It may
              take a few minutes for the UI to update after it {type === "stake" ? "stakes" : "unstakes"} as we
              confirm the transaction on chain.
            </Text>
            <Text>After a few minutes click "Refresh Data"</Text>
            <Checkbox
              label="Dismiss modal for this session"
              checked={checked}
              onChange={handleDismiss}
            />
            <Button onClick={() => setOpened(false)} className="w-full mt-auto">
              OK
            </Button>
          </Group>
        </Group>
      </Modal>
    </>
  );
};

export default NftCard;
