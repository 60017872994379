import styled, { keyframes } from 'styled-components/macro';

export const CollectionRoot = styled('section')`
  padding-top: 20px;

  .heading {
    font-size: 48px;
    text-align: center;
  }

  .info {
    font-size: 30px;
    text-align: center;

    a {
      margin-left: 10px;
      color: #ff4d6e;
      text-decoration: underline;
    }
  }
`;

export const TabButtons = styled('div')`
  display: flex;
  flex-direection: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  column-gap: 10px;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
  padding-bottom: 20px;

  @media (max-width: 950px) {
    flex-wrap: wrap;
    row-gap: 15px;
  }

  button {
   width: 100%;
    border-radius: 3px;
    border: none;
    color: white;

    @media (max-width: 950px) {
      width: 49%;
    }

    @media (max-width: 573px) {
      width: 48%;
    }

    @media (max-width: 485px) {
      width: 100%;
    }
  }
`;

interface ClaimButtonProps { isDisabled: boolean };
export const ClaimButton = styled('button')<ClaimButtonProps>`
  text-decoration: none;
  background-color: #404eed;
  background: -webkit-linear-gradient(left, #eb4762, #ff8522);
  background-size: 100% 100%;
  animation: gradient-animation 4s ease infinite alternate;
  color: #f1f8ff;
  flex: 0 0 auto;
  border-radius: 9px;
  margin-bottom: 8px;
  padding: 8px 32px;
  box-shadow: 0 3px 0 0 rgb(32 32 32 / 5%);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  text-shadow: none;

  display: block;
  border: none;

  font-family: "Oswald";

  min-width: 200px;

  align-self: flex-end;


  opacity: ${({isDisabled}) => isDisabled ? "0.6" : "1"};
  cursor: ${({isDisabled}) => isDisabled ? "not-allowed" : "pointer !important"};
`;

export const CollectionMain = styled('div')`
  padding: 50px 0;
`;

export const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;

  @media (max-width: 900px) {
    grid-gap: 40px;
  };

  @media (max-width:850px) {
    grid-template-columns: repeat(2, 1fr);
  };

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const PairGridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  margin-top: 30px;
  @media (max-width: 900px) {
    grid-gap: 40px;
  };

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  };

  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const ConnectInfo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;


export const LinkButton = styled.a`
  text-decoration: none;
  background-color: #404eed;
  background: -webkit-linear-gradient(left, #eb4762, #ff8522);
  background-size: 100% 100%;
  animation: gradient-animation 4s ease infinite alternate;
  color: #f1f8ff;
  flex: 0 0 auto;
  border-radius: 9px;
  margin-bottom: 8px;
  padding: 8px 32px;
  box-shadow: 0 3px 0 0 rgb(32 32 32 / 5%);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  min-width: 120px;
  text-shadow: none;

  display: block;

`;

export const AlertMessage = styled.div`
  text-align: center;
  align-self: flex-start;
  margin-top: 20px;

  h3 {
    text-shadow: none;
    font-size: 34px;
    font-weight: normal;
    margin-bottom: 30px;
    text-align: center;
  }
`;


export const ellipsis = keyframes`
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
`;

export const ConnectWalletDescription = styled.h3`
  &::after {
    display: inline-block;
    animation: ${ellipsis} 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }

`;