import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from "styled-components/macro";

export const HeaderSection = styled('header')`
  padding: 1rem 0.5rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 50px;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap 20px;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const NavLink = styled('div')`
  display: inline-block;
  text-align: center;
`;

export const NavbarLinkRoot = styled('div')`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  

 

  @media only screen and (max-width: 450px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const RefreshBtn = styled('button')`
  background-color: #0f0f0f;
  color: #fff !important;
  border: 1px solid #fff !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: black !important;
    border: 1px solid black;
    background-color: #fff;
  }

  @media only screen and (max-width: 450px) {
    font-size: 14px !important;
  }
`;
export const WalletInfoText = styled('p')`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 100%;
  margin: 0;
  svg {
    fill: #fff;
    height: 30px;
    width: 30px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 14px !important;
    height: fit-content;
  }
`;

export const CustomNavLink = styled('div')`
  width: 203px;
  display: inline-block;

  a {
    color: #fff !important;
  }

  @media only screen and (max-width: 450px) {
    width: 150px;
    font-size: 14px !important;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled('div')<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? '100%' : '110px')};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled('div')`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;

  svg {
    fill: #2e186a;
  }
`;

export const NotHidden = styled('div')`
  @media only screen and (max-width: 890px) {
    display: block;
  }
`;

export const Menu = styled('h5')`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
`;

export const CustomNavLinkSmall = styled.a`
  display: block;
  text-decoration: none;
  border: 4px solid white;
  padding: 10px 25px;
  border-radius: 5px;

  font-size: 14px;
  font-family: "Oswald";
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;

  width: fit-content;
  min-width: 150px;

  cursor: pointer;

  @media (max-width: 450px) {
    font-size: 14px;
    padding: 8px 15px;
  }

  &:hover {
    background-image: url(orange-background.png);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: fit;
    text-decoration: none !important;
  }
`;

export const Label = styled('span')`
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled('span')`
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

export const DisconnectButton = styled.button`
  display: block;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const ProgressBar = styled.div`
  max-width: 800px;
  width: 100%;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  height: 50px;
  overflow: hidden;
`;

export const Progress = styled.div`
  background: url("img/stripes-white-01.png") rgba(255, 255, 255, 0.85);
  position: relative;
  z-index: 5;

  background-position: center; /* Center the image */
  background-repeat: repeat; /* Do not repeat the image */
  background-size: cover;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 50px;
  margin-right: 20px;
`;

export const ProgressTitle = styled.h2`
  font-size: 28px;
  text-transform: uppercase;

  @media (max-width: 450px) {
    font-size: 22px;
  }
`;

export const StakedAnimalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ConnectButton = styled.button`
  display: block;
`;