import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'basc-raffle'

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  accessToken: 'MC5ZcDdxaEJJQUFDSUFPTEM3.77-977-9V--_vSDvv73vv73vv73vv70NH--_vWbvv71i77-977-977-977-977-977-9Ae-_vQBoIO-_vTPvv73vv73vv719',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
})