import { PublicKey } from '@solana/web3.js';
import { createContext } from 'react';
import { Animal, ContextValues, PairAnimal } from './types';

export const StackContext = createContext<ContextValues>({
  animals: [],
  stakedAnimals: [],
  pairAnimals: [],
  stakedPairAnimals: [],
  getTimestamp: () => new Promise(() => {}),
  getRarityMultiplier: () => 0,
  getPendingStakingRewards: () => 0,
  getPendingPairStakingRewards: (animal: PairAnimal, since: Date) => {return {apeReward: 0, tigerReward: 0}},
  fetchAnimal: (mint: PublicKey) => new Promise(() => {}),
  refreshAnimals: () => new Promise(() => {}),
  fetchUserAccount: () => new Promise(() => {}),
  createAccount: () => new Promise(() => {}),
  stakeAnimal: () => new Promise(() => {}),
  stakePairAnimal: () => new Promise(() => {}),
  unstakeAnimal: () => new Promise(() => {}),
  unstakePairAnimal: () => new Promise(() => {}),
  claimStakingRewards: () => new Promise(() => {}),
  claimPairStakingRewards: () => new Promise(() => {}),
  claimAllStakingRewards: () => new Promise(() => {}),
  claimAllPairStakingRewards: () => new Promise(() => {}),
  stakedAnimalsStatus: {},
  animalsStatus: {},
  avaliableStakedAnimals: [],
  setAvaliableStakedAnimals: (_avaliableStakedAnimals?: Animal[])  => new Promise(() => {}),
  avaliableStakedPairAnimals: [],
  setAvaliableStakedPairAnimals: (_avaliableStakedPairAnimals?: PairAnimal[])  => new Promise(() => {}),
  refetchData: () => new Promise(() => {}),
});
