import { Button, Group } from "@mantine/core";
import { useWalletModal } from "@solana/wallet-adapter-ant-design";
import { useWallet } from "@solana/wallet-adapter-react";
import { Wallet } from "tabler-icons-react";

export default function WalletConnect({
  label,
  className,
}: {
  label?: string;
  className?: string;
}) {
  const wallet = useWallet();
  const modal = useWalletModal();

  const disconnectWallet = async () => {
    await wallet.disconnect();
  };

  return (
    <Group grow className="wallet-connect">
      {!wallet.connected ? (
        <Button
          radius="xl"
          leftIcon={<Wallet />}
          variant="outline"
          onClick={() => modal.setVisible(true)}
        >
          {label ? label : "Connect Wallet"}
        </Button>
      ) : (
        <Button
          radius="xl"
          leftIcon={<Wallet />}
          variant="outline"
          onClick={disconnectWallet}
        >
          Disconnect
        </Button>
      )}
    </Group>
  );
}
