import React from "react";
import {
  FooterWrapper,
  FooterContent,
  Logo,
  SocialIcons,
  SocialIcon,
  Hr,
  Conditions,
} from "./styles";

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <SocialIcons>
          <a
            href="https://discord.com/invite/2uNZYSKvsw"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="discord2.png" />
          </a>
          <a
            href="https://twitter.com/BabyApe_SC?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="twitter2.png" />
          </a>
        </SocialIcons>
        <Logo src="/logo.png" alt="logo" className="mx-7" />
        <SocialIcons>
          <a
            href="https://magiceden.io/marketplace/baby_ape_social_club"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="magic-eden.png" />
          </a>
          <a
            href="https://www.instagram.com/babyapesocialclub/?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            <SocialIcon src="instagram2.png" />
          </a>
        </SocialIcons>
      </FooterContent>
      <Hr />
      <Conditions>© 2022 Baby Ape Social Club | All rights reserved</Conditions>
    </FooterWrapper>
  );
};

export default Footer;
