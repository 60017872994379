import { Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { PairAnimal } from "../../../contexts/Staking/types";
import { useStack } from "../../../hooks/useStaking";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // import plugin
import "dayjs/locale/en";
import {
  Card,
  Group,
  Image,
  Button,
  Title,
  SimpleGrid,
  Text,
  Checkbox,
  Modal,
  Stack,
} from "@mantine/core";

dayjs.extend(relativeTime); // use plugin
dayjs.locale("en"); // use locale

interface Props {
  token: PairAnimal;
  isStaked?: boolean;
}

const NftPairCard = ({ token, isStaked }: Props) => {
  const {
    avaliableStakedPairAnimals,
    setAvaliableStakedPairAnimals,
    getPendingPairStakingRewards,
    stakePairAnimal,
    getTimestamp,
    unstakePairAnimal,
    claimPairStakingRewards,
  } = useStack();

  const [stakingPeriod, setStakingPeriod] = useState<Date>(new Date());
  const [apeRedeemable, setApeRedeemable] = useState<number>(0);
  const [tigerRedeemable, setTigerRedeemable] = useState<number>(0);

  const [opened, setOpened] = useState(false);
  const [type, setType] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        setStakingPeriod(new Date());
      }
    }, 500);
    return () => clearInterval(interval);
  }, [token, getPendingPairStakingRewards, setStakingPeriod]);

  // handle Stake Nft
  const handleStake = useCallback(async () => {
    if (!token) return;
    setType("stake");
    if (localStorage.getItem("pair-stk-modal-dismiss") !== "true")
      setOpened(true);
    await stakePairAnimal(token);
  }, [token, stakePairAnimal]);

  // handle Unstake NFt
  const handleUnstake = useCallback(async () => {
    if (!token) return;
    setType("unstake");
    if (localStorage.getItem("pair-unst-modal-dismiss") !== "true")
      setOpened(true);
    await unstakePairAnimal(token);
  }, [token, unstakePairAnimal]);

  // handle Claim Earning
  const handleClaim = useCallback(async () => {
    if (!token) return;
    try {
      await claimPairStakingRewards(token);
      const now = await getTimestamp();
      const result = avaliableStakedPairAnimals.map((animal) => {
        if (animal.apeMint === token.apeMint) {
          return {
            ...token,
            lastClaim: new Date(now * 1000),
          };
        } else return animal;
      });
      setAvaliableStakedPairAnimals(result);
    } catch (e) {
      console.log(e);
      console.log("Claim Pair Failed.");
    }
  }, [
    token,
    claimPairStakingRewards,
    avaliableStakedPairAnimals,
    setAvaliableStakedPairAnimals,
    getTimestamp,
  ]);

  // pending rewords
  useEffect(() => {
    if (!token?.lastClaim) return;
    if (token?.lastClaim && isStaked) {
      let redeem = 0;
      const timeLog = async () => {
        const timestamp = await getTimestamp();
        redeem = timestamp;
      };
      timeLog();

      return () => {
        if (redeem) {
          const reward = getPendingPairStakingRewards(
            token,
            new Date(redeem * 1000)
          );
          setApeRedeemable(reward.apeReward);
          setTigerRedeemable(reward.tigerReward);
        }
      };
    }
  }, [
    token,
    stakingPeriod,
    getPendingPairStakingRewards,
    isStaked,
    getTimestamp,
  ]);

  const handleDismiss: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setChecked(event.currentTarget.checked);
    localStorage.setItem(
      type === "stake" ? "pair-stk-modal-dismiss" : "pair-unst-modal-dismiss",
      "true"
    );
  };

  return (
    <>
      <Card
        shadow="sm"
        p="lg"
        withBorder
        sx={(theme) => ({
          backgroundColor: theme.other.cardBackgroundColor,
          color: "white",
        })}
      >
        <Group direction="column">
          <div className="w-full">
            {token.apeUriData.image ? (
              <Image src={token.apeUriData.image} alt="" />
            ) : (
              <Skeleton.Avatar active shape="square" size={300} />
            )}

            <Title className="my-3" order={3}>
              {token.apeUriData.name}
            </Title>
            <SimpleGrid cols={2}>
              <span>Rewards Per Day</span>
              <span>
                {(token?.apeEmissionsPerDay || 0) / 10 ** 9}
                <span style={{ fontSize: 12 }}> $DAB</span>
                <span style={{ fontSize: 12 }}> x 1.25</span>
              </span>
            </SimpleGrid>
            {token?.lastClaim && isStaked && (
              <SimpleGrid cols={2}>
                <span>Pending Rewards</span>
                <span>{apeRedeemable.toPrecision(5)}</span>
              </SimpleGrid>
            )}
          </div>

          <Image
            className="md:w-[284px] md:h-[70px] w-full"
            fit="cover"
            src="/vines-3.png"
          />

          <div className="w-full">
            {token.tigerUriData.image ? (
              <Image src={token.tigerUriData.image} alt="" />
            ) : (
              <Skeleton.Avatar active shape="square" size={300} />
            )}

            <Title className="my-3" order={3}>
              {token.tigerUriData.name}
            </Title>
            <SimpleGrid cols={2}>
              <span>Rewards Per Day</span>{" "}
              <span>
                {(token?.tigerEmissionsPerDay || 0) / 10 ** 9}
                <span style={{ fontSize: 12 }}> $DAB</span>
                <span style={{ fontSize: 12 }}> x 1.25</span>
              </span>
            </SimpleGrid>
            {token?.lastClaim && isStaked && (
              <SimpleGrid cols={2}>
                <span>Pending Rewards</span>{" "}
                <span>{tigerRedeemable.toPrecision(5)}</span>
              </SimpleGrid>
            )}
          </div>
        </Group>

        <Group grow>
          {token?.lastClaim && isStaked ? (
            <Group className="mt-5" grow>
              <Button
                sx={() => ({
                  color: "white",
                  borderColor: "white",
                })}
                variant="outline"
                onClick={handleClaim}
              >
                Claim
              </Button>
              <Button
                sx={() => ({
                  color: "white",
                  borderColor: "white",
                })}
                variant="outline"
                onClick={handleUnstake}
              >
                Unstake
              </Button>
            </Group>
          ) : (
            <Button
              sx={() => ({
                color: "white",
                borderColor: "white",
              })}
              className="my-3"
              variant="outline"
              onClick={handleStake}
            >
              Stake Pair
            </Button>
          )}
        </Group>
      </Card>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Title order={3}>
            Matching Pair is {type === "stake" ? "Staking" : "Unstaking"}!
          </Title>
        }
        size={556}
        centered
      >
        <Stack>
          <Group>
            <Image width={250} src={token.tigerUriData.image} />
            <Image width={250} src={token.apeUriData.image} />
          </Group>

          <Group direction="column" position="apart" className="h-[250px]">
            <Text>
              Your matching pairs are {type === "stake" ? "staking" : "unstaking"}. It may
              take a few minutes for the UI to update after it {type === "stake" ? "stakes" : "unstakes"} as we
              confirm the transaction on chain.
            </Text>
            <Text>After a few minutes click "Refresh Data"</Text>
            <Checkbox
              label="Dismiss modal for this session"
              checked={checked}
              onChange={handleDismiss}
            />
            <Button onClick={() => setOpened(false)} className="w-full mt-auto">
              OK
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default NftPairCard;
