import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import * as anchor from "@project-serum/anchor";
import {
  AccountInfo as TokenAccount,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants, {
  DECIMALS,
  EPOCH,
  GLOBAL_AUTHORITY_SEED,
  HIGH_APE_REWARD,
  HIGH_TIGER_REWARD,
  MATCHING_FACTOR,
  NORMAL_APE_REWARD,
  NORMAL_TIGER_REWARD,
  REWARD_TOKEN_MINT,
  SUPER_APE_REWARD,
  SUPER_TIGER_REWARD,
  USER_PAIR_POOL_SIZE,
  USER_POOL_SIZE,
} from "../../constants";
import idl from "../../constants/idls/basc_staking.json";
import {
  IDL as BascStakingIdl,
  BascStaking as BascStakingProgram,
} from "../../constants/types/basc_staking";
import {
  buildLeaves,
  factionToNumber,
  getAssociatedTokenAccount,
  getATokenAccountsNeedCreate,
  getMetadata,
  getNFTTokenAccount,
  getOwnerOfNFT,
  METAPLEX,
} from "../../utils";
import { MerkleTree } from "../../utils/merkleTree";
import { StackContext } from "./Context";
import {
  Animal,
  GlobalPool,
  Jungle,
  PairAnimal,
  UserPairPool,
  UserPool,
} from "./types";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"; // import plugin
import "dayjs/locale/en";
import axios from "axios";

dayjs.extend(relativeTime); // use plugin
dayjs.locale("en"); // use locale

// Program Id
const programID = new PublicKey(idl.metadata.address);

export interface Props {
  children: ReactNode;
  connection: anchor.web3.Connection;
}

// Staking Provider Main
export const StakingProvider = (props: Props) => {
  const wallet = useWallet();
  const [userAccount, setUserAccount] = useState<TokenAccount>();
  const [animals, setAnimals] = useState<Animal[]>();
  const [pairAnimals, setPairAnimals] = useState<PairAnimal[]>();
  const [jungle, setJungle] = useState<Jungle>();
  const [globalInfo, setGlobalInfo] = useState<GlobalPool>();
  const [stakedAnimals, setStakedAnimals] = useState<Animal[]>();
  const [stakedPairAnimals, setStakedPairAnimals] = useState<PairAnimal[]>();
  const [stats, setStats] = useState<any>();
  const [animalsStatus, setAnimalsStatus] = useState<any>({
    loading: false,
    loadingEnd: true,
    finish: true,
  });
  const [stakedAnimalsStatus, setStakedAnimalsStatus] = useState<any>({
    loading: false,
    loadingEnd: true,
    finish: true,
  });
  const [avaliableStakedAnimals, setStateAvaliableStakedAnimals] = useState<
    Animal[]
  >([]);
  const [avaliableStakedPairAnimals, setStateAvaliableStakedPairAnimals] =
    useState<PairAnimal[]>([]);

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }
    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    };
  }, [wallet]);

  // confirmed provider
  const provider = useMemo(() => {
    if (!props.connection) return;
    return new anchor.Provider(props.connection, wallet as any, {
      preflightCommitment: "confirmed",
    });
  }, [props.connection, wallet]);

  const tree = useMemo(() => {
    const leaves = buildLeaves(
      //@ts-ignore
      constants.metadata.map((e, i) => ({
        mint: new PublicKey(e.mint),
        emissionsPerDay: e.emissionsPerDay,
        faction: factionToNumber(e.faction),
      }))
    );
    return new MerkleTree(leaves);
  }, []);


  const getAvaliableStakedPairAnimals = useCallback(async () => {
    setStateAvaliableStakedPairAnimals(stakedPairAnimals as PairAnimal[]);
  }, [stakedPairAnimals]);

  const setAvaliableStakedPairAnimals = useCallback(
    async (_avaliableStakedPairAnimals?: PairAnimal[]) => {
      if (_avaliableStakedPairAnimals)
        setStateAvaliableStakedPairAnimals(_avaliableStakedPairAnimals);
      else await getAvaliableStakedPairAnimals();
    },
    [getAvaliableStakedPairAnimals]
  );


  const getAvaliableStakedAnimals = useCallback(async () => {
    // let updatedAvaliableAnimals: Animal[]  = [];
    // const result = stakedAnimals?.map(async (augmentedAnimal) => {
    //   try {
    //     const animal = await fetchAnimal(augmentedAnimal.mint);
    //     if (animal)
    //       return animal;
    //     else
    //       return augmentedAnimal;
    //   } catch (e) {
    //     console.log(e);
    //     return augmentedAnimal;
    //   }
    // });

    // updatedAvaliableAnimals = await Promise.all(result || []);
    // setStateAvaliableStakedAnimals(updatedAvaliableAnimals);
    setStateAvaliableStakedAnimals(stakedAnimals as Animal[]);
  }, [stakedAnimals]);

  const setAvaliableStakedAnimals = useCallback(
    async (_avaliableStakedAnimals?: Animal[]) => {
      if (_avaliableStakedAnimals)
        setStateAvaliableStakedAnimals(_avaliableStakedAnimals);
      else await getAvaliableStakedAnimals();
    },
    [getAvaliableStakedAnimals]
  );

  const refetchData = async () => {
    await fetchAnimals();
    await fetchStakedAnimals();
  }

  // fetch own animals
  const fetchAnimals = useCallback(async () => {
    if (!props.connection || !wallet.publicKey) return;
    try {
      setAnimalsStatus({
        loading: true,
        loadingEnd: false,
        finish: false,
      });
      const owned = await Metadata.findDataByOwner(
        props.connection,
        wallet.publicKey
      );

      const collectionMints = constants.metadata.map((e) => e.mint);
      let pairData: PairAnimal[] = [];
      const data = owned
        .map((e) => e.mint)
        .filter((e) => collectionMints.includes(e))
        .map((e) => {
          const metadataItem = constants.metadata.filter(
            (f) => f.mint === e
          )[0];
          const result = {
            mint: new PublicKey(e),
            metadata: metadataItem.arweave,
            rank: metadataItem.rank,
            uriData: metadataItem.metadata,
            emissionsPerDay: metadataItem.emissionsPerDay,
            faction: metadataItem.faction,
          };
          return result;
        })
        .sort((a, b) => {
          const na = Number(a.metadata.name.split("#")[1]);
          const nb = Number(b.metadata.name.split("#")[1]);
          if (a.faction === "basc" && b.faction === "batc" && na === nb) {
            if (!pairData.map((data) => data.apeMint).includes(a.mint))
              pairData.push({
                apeMint: a.mint,
                apeUriData: a.uriData,
                apeRank: a.rank,
                apeMetadata: a.metadata,
                apeEmissionsPerDay: a.emissionsPerDay,
                tigerMint: b.mint,
                tigerUriData: b.uriData,
                tigerRank: b.rank,
                tigerMetadata: b.metadata,
                tigerEmissionsPerDay: b.emissionsPerDay,
              });
          } else if (
            a.faction === "batc" &&
            b.faction === "basc" &&
            na === nb
          ) {
            if (!pairData.map((data) => data.apeMint).includes(b.mint))
              pairData.push({
                apeMint: b.mint,
                apeUriData: b.uriData,
                apeRank: b.rank,
                apeMetadata: b.metadata,
                apeEmissionsPerDay: b.emissionsPerDay,
                tigerMint: a.mint,
                tigerUriData: a.uriData,
                tigerRank: a.rank,
                tigerMetadata: a.metadata,
                tigerEmissionsPerDay: a.emissionsPerDay,
              });
          }
          return na - nb;
        });

      setAnimals(data);

      // Extract pairs
      setPairAnimals(pairData);
    } catch (err) {
      console.log("Failed fetching owned tokens", err);
    } finally {
      setAnimalsStatus({
        loading: false,
        loadingEnd: true,
        finish: true,
      });
    }
  }, [wallet, props.connection]);


  useEffect(() => {
    fetchAnimals();
  }, [anchorWallet, fetchAnimals]);

  // Fetches the animals staked by the user

  const fetchStakedAnimals = useCallback(async () => {
    if (!props.connection || !wallet.publicKey) return;
    console.log("Fetch Staked Info again");
    setStakedAnimalsStatus({
      loading: true,
      loadingEnd: false,
      finish: false,
    });
    const program = new anchor.Program(idl as anchor.Idl, programID, provider);

    try {
      let userPoolKey = await PublicKey.createWithSeed(
        wallet.publicKey,
        "user-pool",
        program.programId
      );
      console.log("User Pool: ", userPoolKey.toBase58());

      let staked,
        lastRewardTime = 0;
      try {
        let poolState = await program.account.userPool.fetch(userPoolKey);
        staked = (poolState as UserPool).stakedNfts.slice(
          0,
          (poolState as UserPool).stakedCount.toNumber()
        );
        lastRewardTime = (poolState as UserPool).lastRewardTime.toNumber();
      } catch (e) {
        throw e;
      }

      const collectionMints = constants.metadata.map((e) => e.mint);
      const data = staked
        .map((e) => {
          return {
            mint: e.mint.toString(),
            lastClaim: e.lastClaimedTime.toNumber(),
          };
        })
        .filter((e) => collectionMints.includes(e.mint))
        .map((e) => {
          const metadataItem = constants.metadata.filter(
            (f) => f.mint === e.mint
          )[0];
          return {
            mint: new PublicKey(e.mint),
            metadata: metadataItem.arweave,
            rank: metadataItem.rank,
            uriData: metadataItem.metadata,
            emissionsPerDay: metadataItem.emissionsPerDay,
            faction: metadataItem.faction,
            lastClaim: new Date(
              (lastRewardTime < e.lastClaim ? e.lastClaim : lastRewardTime) *
                1000
            ),
          };
        })
        .sort((a, b) => {
          const na = Number(a.metadata.name.split("#")[1]);
          const nb = Number(b.metadata.name.split("#")[1]);
          return na - nb;
        });
      console.log(
        data.map((e) => e.lastClaim),
        Date.now()
      );
      setStakedAnimals(data);
      setAvaliableStakedAnimals(data);
    } catch (err) {
      console.log("Failed fetching owned tokens", err);
    } finally {
      // setStakedAnimalsStatus({
      //   loading: false,
      //   loadingEnd: true,
      //   finish: true,
      // });
    }

    try {
      let userPoolKey = await PublicKey.createWithSeed(
        wallet.publicKey,
        "user-pair-pool",
        program.programId
      );
      console.log("User Pair Pool: ", userPoolKey.toBase58());

      let staked,
        lastRewardTime = 0;
      try {
        let poolState = await program.account.userPairPool.fetch(userPoolKey);
        staked = (poolState as UserPairPool).stakedPairs.slice(
          0,
          (poolState as UserPairPool).stakedPairCount.toNumber()
        );
        lastRewardTime = (poolState as UserPairPool).lastRewardTime.toNumber();
      } catch (e) {
        throw e;
      }

      const collectionMints = constants.metadata.map((e) => e.mint);
      const data = staked
        .map((e) => {
          return {
            apeMint: e.apeMint.toString(),
            tigerMint: e.tigerMint.toString(),
            lastClaim: e.lastClaimedTime.toNumber(),
          };
        })
        .filter(
          (e) =>
            collectionMints.includes(e.apeMint) &&
            collectionMints.includes(e.tigerMint)
        )
        .map((e) => {
          const apeMetadataItem = constants.metadata.filter(
            (f) => f.mint === e.apeMint
          )[0];
          const tigerMetadataItem = constants.metadata.filter(
            (f) => f.mint === e.tigerMint
          )[0];
          return {
            apeMint: new PublicKey(e.apeMint),
            apeMetadata: apeMetadataItem.arweave,
            apeRank: apeMetadataItem.rank,
            apeUriData: apeMetadataItem.metadata,
            apeEmissionsPerDay: apeMetadataItem.emissionsPerDay,
            tigerMint: new PublicKey(e.tigerMint),
            tigerMetadata: tigerMetadataItem.arweave,
            tigerRank: tigerMetadataItem.rank,
            tigerUriData: tigerMetadataItem.metadata,
            tigerEmissionsPerDay: tigerMetadataItem.emissionsPerDay,
            lastClaim: new Date(
              (lastRewardTime < e.lastClaim ? e.lastClaim : lastRewardTime) *
                1000
            ),
          };
        })
        .sort((a, b) => {
          const na = Number(a.apeMetadata.name.split("#")[1]);
          const nb = Number(b.apeMetadata.name.split("#")[1]);
          return na - nb;
        });

      setStakedPairAnimals(data);
      setAvaliableStakedPairAnimals(data);
    } catch (err) {
      console.log("Failed fetching owned tokens", err);
    } finally {
      setStakedAnimalsStatus({
        loading: false,
        loadingEnd: true,
        finish: true,
      });
    }
  }, [provider, wallet, props.connection]);

  useEffect(() => {
    fetchStakedAnimals();
  }, [anchorWallet, fetchStakedAnimals]);

  // Fetch jungle
  const fetchJungle = useCallback(async () => {
    if (!provider) return;
    const program = new anchor.Program<BascStakingProgram>(
      BascStakingIdl,
      programID,
      provider
    );

    const [globalAuthority, bump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );

    let globalState = await program.account.globalPool.fetch(globalAuthority);
    setGlobalInfo(globalState as GlobalPool);
    
  }, [provider]);

  useEffect(() => {
    fetchJungle();
  }, [anchorWallet, fetchJungle]);

  // Fetches the staking rewards account

  const fetchUserAccount = useCallback(async () => {
    if (!props.connection || !wallet || !wallet.publicKey) return;

    try {
      const associatedAddress = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        new PublicKey("32CHtMAuGaCAZx8Rgp54jSFG3ihbpN5brSvRAWpwEHPv"),
        wallet.publicKey
      );

      const token = new Token(
        props.connection,
        new PublicKey("32CHtMAuGaCAZx8Rgp54jSFG3ihbpN5brSvRAWpwEHPv"),
        TOKEN_PROGRAM_ID,
        wallet as any
      );

      const userAccount = await token.getAccountInfo(associatedAddress);
      setUserAccount(userAccount);
    } catch (err) {
      console.log('User has no account yet');
    }
  }, [props.connection, jungle, wallet]);

  useEffect(() => {
    fetchUserAccount();
  }, [fetchUserAccount]);

  const createAccount = useCallback(async () => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signTransaction ||
      !jungle ||
      !anchor.Provider
    )
      return;
    // try {
    //   fetchUserAccount();
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   console.log('finish');
    // }
  }, [jungle, wallet, fetchUserAccount]);

  const getRarityMultiplier = useCallback(
    (animal: Animal) => {
      if (!jungle) return;

      return (
        ((Math.min(
          jungle.maximumRarity.toNumber(),
          animal.emissionsPerDay || animal.emissionsPerDay
        ) /
          jungle.maximumRarity.toNumber()) *
          (jungle.maximumRarityMultiplier.toNumber() - 10000) +
          10000) /
        10000
      );
    },
    [jungle]
  );

  const getPendingStakingRewards = useCallback(
    (animal: Animal, now: Date) => {
      if (!animal.lastClaim || now < animal.lastClaim) return 0;
      const elapsed = (now.valueOf() - animal.lastClaim.valueOf()) / 1000;
      let rewardAmount;
      if (animal.faction === "basc") {
        if (animal.rank < 13) {
          rewardAmount = SUPER_APE_REWARD;
        } else if (animal.rank < 1000) {
          rewardAmount = HIGH_APE_REWARD;
        } else {
          rewardAmount = NORMAL_APE_REWARD;
        }
      } else if (animal.faction === "batc") {
        if (animal.rank < 5013) {
          rewardAmount = SUPER_TIGER_REWARD;
        } else if (animal.rank < 6000) {
          rewardAmount = HIGH_TIGER_REWARD;
        } else {
          rewardAmount = NORMAL_TIGER_REWARD;
        }
      } else {
        // console.log('Unknown Collection');
        return 0;
      }
      const pendingRewards = Math.floor(elapsed / EPOCH) * rewardAmount;

      return pendingRewards / DECIMALS;
    },
    [jungle]
  );

  const getPendingPairStakingRewards = useCallback(
    (animal: PairAnimal, now: Date) => {
      if (!animal.lastClaim || now < animal.lastClaim)
        return {
          apeReward: 0,
          tigerReward: 0,
        };
      const elapsed = (now.valueOf() - animal.lastClaim.valueOf()) / 1000;
      let rewardAmount;

      if (animal.apeRank < 13) {
        rewardAmount = SUPER_APE_REWARD;
      } else if (animal.apeRank < 1000) {
        rewardAmount = HIGH_APE_REWARD;
      } else {
        rewardAmount = NORMAL_APE_REWARD;
      }

      const apePendingRewards = Math.floor(elapsed / EPOCH) * rewardAmount;

      if (animal.tigerRank < 5013) {
        rewardAmount = SUPER_TIGER_REWARD;
      } else if (animal.tigerRank < 6000) {
        rewardAmount = HIGH_TIGER_REWARD;
      } else {
        rewardAmount = NORMAL_TIGER_REWARD;
      }

      const tigerPendingRewards = Math.floor(elapsed / EPOCH) * rewardAmount;

      let factor = 100;
      const na = Number(animal.apeMetadata.name.split("#")[1]);
      const nt = Number(animal.tigerMetadata.name.split("#")[1]);

      if (na === nt) factor = MATCHING_FACTOR;

      return {
        apeReward: Math.floor((apePendingRewards * factor) / 100) / DECIMALS,
        tigerReward:
          Math.floor((tigerPendingRewards * factor) / 100) / DECIMALS,
      };
    },
    [jungle]
  );

  // stake animal
  const stakeAnimal = useCallback(
    async (animalData: Animal | Animal[], bunchSending?: boolean) => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !provider
      )
        return;
      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      let animal: Animal,
        transactions: Transaction[] = [],
        index = 0;
      let joinToast = toast.loading(
        `${
          !bunchSending
            ? (animalData as Animal).metadata.name
            : `${(animalData as Animal[]).length} NFTs`
        } Staking..`
      );
      try {
        while (1) {
          if (!bunchSending) animal = animalData as Animal;
          else {
            if (index == (animalData as Animal[]).length) break;
            animal = (animalData as Animal[])[index];
          }

          let userTokenAccount = await getAssociatedTokenAccount(
            wallet.publicKey,
            animal.mint
          );
          console.log("userTokenAccount: ", userTokenAccount);
          console.log(" wallet.publicKey: ",  wallet.publicKey.toBase58());
          console.log("animal.mint: ", animal.mint.toBase58());
          const res = await provider.connection.getTokenAccountBalance(
            userTokenAccount
          );
          if (res && res.value.uiAmount === 1) {
          } else {
            let accountOfNFT = await getNFTTokenAccount(
              animal.mint,
              provider.connection
            );
            if (userTokenAccount.toBase58() != accountOfNFT.toBase58()) {
              let nftOwner = await getOwnerOfNFT(
                provider.connection,
                animal.mint
              );
              if (nftOwner.toBase58() == wallet.publicKey.toBase58())
                userTokenAccount = accountOfNFT;
              else {
                throw "Error: Nft is not owned by user";
              }
            }
          }
          console.log(
            "BASC NFT = ",
            animal.mint.toBase58(),
            userTokenAccount.toBase58()
          );

          const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            program.programId
          );
          console.log("globalAuthority", globalAuthority.toBase58());
          let { instructions, destinationAccounts } =
            await getATokenAccountsNeedCreate(
              provider.connection,
              wallet.publicKey,
              globalAuthority,
              [animal.mint]
            );
          console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());

          let userPoolKey = await PublicKey.createWithSeed(
            wallet.publicKey,
            "user-pool",
            program.programId
          );

          let poolAccount = await provider.connection.getAccountInfo(
            userPoolKey
          );
          if (poolAccount === null || poolAccount.data === null) {
            // Initialize User Pool
            let tx = new Transaction();
            let ix = SystemProgram.createAccountWithSeed({
              fromPubkey: wallet.publicKey,
              basePubkey: wallet.publicKey,
              seed: "user-pool",
              newAccountPubkey: userPoolKey,
              lamports:
                await provider.connection.getMinimumBalanceForRentExemption(
                  USER_POOL_SIZE
                ),
              space: USER_POOL_SIZE,
              programId: program.programId,
            });
            tx.add(ix);
            tx.add(
              program.instruction.initializeUserPool({
                accounts: {
                  userPool: userPoolKey,
                  owner: wallet.publicKey,
                },
                instructions: [],
                signers: [],
              })
            );
            console.log(tx);
            const txId = await wallet.sendTransaction(tx, provider.connection);
            await provider.connection.confirmTransaction(txId, "finalized");
            console.log("Initialized transaction signature", tx);
          }

          const metadata = await getMetadata(animal.mint);
          console.log("Metadata=", metadata.toBase58());

          const tx = new Transaction();
          if (instructions.length > 0) tx.add(instructions[0]);
          tx.add(
            program.instruction.stakeNftToPool(
              bump,
              new anchor.BN(animal.rank),
              {
                accounts: {
                  owner: wallet.publicKey,
                  userPool: userPoolKey,
                  globalAuthority,
                  userTokenAccount,
                  destNftTokenAccount: destinationAccounts[0],
                  nftMint: animal.mint,
                  mintMetadata: metadata,
                  tokenProgram: TOKEN_PROGRAM_ID,
                  tokenMetadataProgram: METAPLEX,
                },
                instructions: [],
                signers: [],
              }
            )
          );
          console.log(tx);
          transactions.push(tx);
          if (!bunchSending) break;
          else index++;
        }

        let { blockhash } = await provider.connection.getRecentBlockhash(
          "confirmed"
        );

        transactions.forEach((transaction) => {
          transaction.feePayer = wallet.publicKey as PublicKey;
          transaction.recentBlockhash = blockhash;
        });
        const signedTransactions = await wallet.signAllTransactions(
          transactions
        );

        let signatures = await Promise.all(
          signedTransactions.map(
            (transaction) =>
              provider.connection.sendRawTransaction(transaction.serialize(), {
                skipPreflight: true,
                maxRetries: 3,
                preflightCommitment: "confirmed",
              })
            // wallet.sendTransaction(transaction, provider.connection, {maxRetries: 3, preflightCommitment: 'confirmed'})
          )
        );
        await Promise.all(
          signatures.map((signature) =>
            provider.connection.confirmTransaction(signature, "finalized")
          )
        );

        toast.update(joinToast, {
          render: `${
            !bunchSending
              ? (animalData as Animal).metadata.name
              : `${(animalData as Animal[]).length} NFTs`
          } successfully Staked!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } catch (err) {
        meaningfulErrorOutput(err, `Staking Failed!`, joinToast);
      } finally {
        joinToast = toast.loading(`Re-fetching data...`);
        await fetchAnimals();
        await fetchStakedAnimals();
        await fetchJungle();
        toast.update(joinToast, {
          render: `Data refetched!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      }
    },
    [jungle, provider, tree, wallet, fetchAnimals, fetchStakedAnimals, fetchJungle]
  );

  // stake pair animal
  const stakePairAnimal = useCallback(
    async (animalData: PairAnimal | PairAnimal[], bunchSending?: boolean) => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !provider
      )
        return;
      let joinToast = toast.loading(
        `${
          !bunchSending
            ? (animalData as PairAnimal).apeMetadata.name
            : `${(animalData as PairAnimal[]).length} NFTs`
        } Staking..`
      );
      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      let animal: PairAnimal,
        transactions: Transaction[] = [],
        index = 0;

      try {
        while (1) {
          if (!bunchSending) animal = animalData as PairAnimal;
          else {
            if (index == (animalData as PairAnimal[]).length) break;
            animal = (animalData as PairAnimal[])[index];
          }

          let userApeTokenAccount = await getAssociatedTokenAccount(
            wallet.publicKey,
            animal.apeMint
          );
          let res = await provider.connection.getTokenAccountBalance(
            userApeTokenAccount
          );
          if (res && res.value.uiAmount === 1) {
          } else {
            let apeAccountOfNFT = await getNFTTokenAccount(
              animal.apeMint,
              provider.connection
            );
            if (userApeTokenAccount.toBase58() != apeAccountOfNFT.toBase58()) {
              let nftOwner = await getOwnerOfNFT(
                provider.connection,
                animal.apeMint
              );
              if (nftOwner.toBase58() == wallet.publicKey.toBase58())
                userApeTokenAccount = apeAccountOfNFT;
              else {
                throw "Error: Ape Nft is not owned by user";
              }
            }
          }
          let userTigerTokenAccount = await getAssociatedTokenAccount(
            wallet.publicKey,
            animal.tigerMint
          );
          res = await provider.connection.getTokenAccountBalance(
            userApeTokenAccount
          );
          if (res && res.value.uiAmount === 1) {
          } else {
            let tigerAccountOfNFT = await getNFTTokenAccount(
              animal.tigerMint,
              provider.connection
            );
            if (
              userTigerTokenAccount.toBase58() != tigerAccountOfNFT.toBase58()
            ) {
              let nftOwner = await getOwnerOfNFT(
                provider.connection,
                animal.tigerMint
              );
              if (nftOwner.toBase58() == wallet.publicKey.toBase58())
                userTigerTokenAccount = tigerAccountOfNFT;
              else {
                throw "Error: Tiger Nft is not owned by user";
              }
            }
          }
          console.log(
            "BASC Ape NFT = ",
            animal.apeMint.toBase58(),
            userApeTokenAccount.toBase58()
          );
          console.log(
            "BASC Tiger NFT = ",
            animal.tigerMint.toBase58(),
            userTigerTokenAccount.toBase58()
          );

          const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            program.programId
          );
          console.log("globalAuthority", globalAuthority.toBase58());

          let retApe = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            globalAuthority,
            [animal.apeMint]
          );
          console.log(
            "Dest Ape NFT Account = ",
            retApe.destinationAccounts[0].toBase58()
          );

          let retTiger = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            globalAuthority,
            [animal.tigerMint]
          );
          console.log(
            "Dest Tiger NFT Account = ",
            retTiger.destinationAccounts[0].toBase58()
          );

          let userPoolKey = await PublicKey.createWithSeed(
            wallet.publicKey,
            "user-pair-pool",
            program.programId
          );

          let poolAccount = await provider.connection.getAccountInfo(
            userPoolKey
          );
          if (poolAccount === null || poolAccount.data === null) {
            // Initialize User Pool
            let tx = new Transaction();
            let ix = SystemProgram.createAccountWithSeed({
              fromPubkey: wallet.publicKey,
              basePubkey: wallet.publicKey,
              seed: "user-pair-pool",
              newAccountPubkey: userPoolKey,
              lamports:
                await provider.connection.getMinimumBalanceForRentExemption(
                  USER_PAIR_POOL_SIZE
                ),
              space: USER_PAIR_POOL_SIZE,
              programId: program.programId,
            });
            tx.add(ix);
            tx.add(
              program.instruction.initializeUserPairPool({
                accounts: {
                  userPool: userPoolKey,
                  owner: wallet.publicKey,
                },
                instructions: [],
                signers: [],
              })
            );
            const txId = await wallet.sendTransaction(tx, provider.connection);
            await provider.connection.confirmTransaction(txId, "finalized");
            console.log("Initialized transaction signature", tx);
          }

          const apeMetadata = await getMetadata(animal.apeMint);
          console.log("ApeMetadata=", apeMetadata.toBase58());

          const tigerMetadata = await getMetadata(animal.tigerMint);
          console.log("TigerMetadata=", tigerMetadata.toBase58());

          const tx = new Transaction();
          if (retApe.instructions.length > 0) tx.add(retApe.instructions[0]);
          if (retTiger.instructions.length > 0)
            tx.add(retTiger.instructions[0]);

          tx.add(
            program.instruction.stakeNftToPairPool(
              bump,
              new anchor.BN(animal.apeRank),
              new anchor.BN(animal.tigerRank),
              {
                accounts: {
                  owner: wallet.publicKey,
                  userPool: userPoolKey,
                  globalAuthority,
                  userApeTokenAccount,
                  destApeNftTokenAccount: retApe.destinationAccounts[0],
                  apeNftMint: animal.apeMint,
                  apeMintMetadata: apeMetadata,
                  userTigerTokenAccount,
                  destTigerNftTokenAccount: retTiger.destinationAccounts[0],
                  tigerNftMint: animal.tigerMint,
                  tigerMintMetadata: tigerMetadata,
                  tokenProgram: TOKEN_PROGRAM_ID,
                  tokenMetadataProgram: METAPLEX,
                },
                instructions: [],
                signers: [],
              }
            )
          );

          transactions.push(tx);
          if (!bunchSending) break;
          else index++;
        }

        let { blockhash } = await provider.connection.getRecentBlockhash(
          "confirmed"
        );

        transactions.forEach((transaction) => {
          transaction.feePayer = wallet.publicKey as PublicKey;
          transaction.recentBlockhash = blockhash;
        });
        const signedTransactions = await wallet.signAllTransactions(
          transactions
        );

        let signatures = await Promise.all(
          signedTransactions.map(
            (transaction) =>
              provider.connection.sendRawTransaction(transaction.serialize(), {
                skipPreflight: true,
                maxRetries: 3,
                preflightCommitment: "confirmed",
              })
            // wallet.sendTransaction(transaction, provider.connection)
          )
        );
        await Promise.all(
          signatures.map((signature) =>
            provider.connection.confirmTransaction(signature, "finalized")
          )
        );

        toast.update(joinToast, {
          render: `${
            !bunchSending
              ? (animalData as PairAnimal).apeMetadata.name
              : `${(animalData as PairAnimal[]).length} NFTs`
          } successfully Staked!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } catch (err) {
        meaningfulErrorOutput(err, `Staking Failed!`, joinToast);
      } finally {
        joinToast = toast.loading(`Re-fetching data...`);
        await fetchAnimals();
        await fetchStakedAnimals();
        await fetchJungle();
        await fetchStakedAnimals();
        toast.update(joinToast, {
          render: `User pool updated!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      }
    },
    [jungle, provider, tree, wallet, fetchAnimals, fetchStakedAnimals, fetchJungle]
  );

  // Unstakes an animal.
  // It also creates all used account if they do not exist and claims rewards

  const unstakeAnimal = useCallback(
    async (animalData: Animal | Animal[], bunchSending?: boolean) => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !provider
      )
        return;

      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      let animal: Animal,
        transactions: Transaction[] = [],
        index = 0;
      let unStakeToast = toast.loading(
        `${
          !bunchSending
            ? (animalData as Animal).metadata.name
            : `${(animalData as Animal[]).length} NFTs`
        } Unstaking..`
      );

      try {
        while (1) {
          if (!bunchSending) animal = animalData as Animal;
          else {
            if (index == (animalData as Animal[]).length) break;
            animal = (animalData as Animal[])[index];
          }
          let retUser = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            wallet.publicKey,
            [animal.mint]
          );
          let tx = new Transaction();
          if (retUser.instructions.length > 0) tx.add(retUser.instructions[0]);
          let userTokenAccount = retUser.destinationAccounts[0];
          console.log(
            "BASC NFT = ",
            animal.mint.toBase58(),
            userTokenAccount.toBase58()
          );

          const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            program.programId
          );

          let { instructions, destinationAccounts } =
            await getATokenAccountsNeedCreate(
              provider.connection,
              wallet.publicKey,
              globalAuthority,
              [animal.mint]
            );
          console.log("Dest NFT Account = ", destinationAccounts[0].toBase58());

          let userPoolKey = await PublicKey.createWithSeed(
            wallet.publicKey,
            "user-pool",
            program.programId
          );

          let ret = await getATokenAccountsNeedCreate(
            provider?.connection,
            wallet.publicKey,
            wallet.publicKey,
            [REWARD_TOKEN_MINT]
          );
          console.log(
            "User Reward Account = ",
            ret.destinationAccounts[0].toBase58()
          );

          if (ret.instructions.length > 0) tx.add(ret.instructions[0]);

          let rewardVault = await getAssociatedTokenAccount(
            globalAuthority,
            REWARD_TOKEN_MINT
          );
          console.log("RewardVault: ", rewardVault.toBase58());

          tx.add(
            program.instruction.withdrawNftFromPool(bump, {
              accounts: {
                owner: wallet.publicKey,
                userPool: userPoolKey,
                globalAuthority,
                userTokenAccount,
                destNftTokenAccount: destinationAccounts[0],
                nftMint: animal.mint,
                rewardVault,
                userRewardAccount: ret.destinationAccounts[0],
                tokenProgram: TOKEN_PROGRAM_ID,
              },
              instructions: [],
              signers: [],
            })
          );

          transactions.push(tx);
          if (!bunchSending) break;
          else index++;
        }
        let { blockhash } = await provider.connection.getRecentBlockhash(
          "confirmed"
        );

        transactions.forEach((transaction) => {
          transaction.feePayer = wallet.publicKey as PublicKey;
          transaction.recentBlockhash = blockhash;
        });
        const signedTransactions = await wallet.signAllTransactions(
          transactions
        );

        let signatures = await Promise.all(
          signedTransactions.map(
            (transaction) =>
              provider.connection.sendRawTransaction(transaction.serialize(), {
                skipPreflight: true,
                maxRetries: 3,
                preflightCommitment: "confirmed",
              })
            // wallet.sendTransaction(transaction, provider.connection)
          )
        );
        await Promise.all(
          signatures.map((signature) =>
            provider.connection.confirmTransaction(signature, "finalized")
          )
        );

        toast.update(unStakeToast, {
          render: `${
            !bunchSending
              ? (animalData as Animal).metadata.name
              : `${(animalData as Animal[]).length} NFTs`
          } has successfully Unstaked`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } catch (err) {
        meaningfulErrorOutput(err, `Failed to Unstake!`, unStakeToast);
      } finally {
        unStakeToast = toast.loading(`Re-fetching data...`);
        await fetchAnimals();
        await fetchStakedAnimals();
        await fetchJungle();
        toast.update(unStakeToast, {
          render: `User pool updated!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      }
    },
    [
      // jungle,
      provider,
      // userAccount,
      wallet,
      fetchAnimals,
      fetchStakedAnimals,
      fetchJungle,
      jungle,
    ]
  );

  // Unstakes a pair animal.
  // It also creates all used account if they do not exist and claims rewards
  const unstakePairAnimal = useCallback(
    async (animalData: PairAnimal | PairAnimal[], bunchSending?: boolean) => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !provider
      )
        return;

      let unStakeToast = toast.loading(
        `${
          !bunchSending
            ? (animalData as PairAnimal).apeMetadata.name
            : `${(animalData as PairAnimal[]).length} NFTs`
        } Unstaking..`
      );
      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      let animal: PairAnimal,
        transactions: Transaction[] = [],
        index = 0;

      try {
        while (1) {
          if (!bunchSending) animal = animalData as PairAnimal;
          else {
            if (index == (animalData as PairAnimal[]).length) break;
            animal = (animalData as PairAnimal[])[index];
          }

          let retUserApe = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            wallet.publicKey,
            [animal.apeMint]
          );
          let tx = new Transaction();
          if (retUserApe.instructions.length > 0)
            tx.add(retUserApe.instructions[0]);
          let userApeTokenAccount = retUserApe.destinationAccounts[0];
          console.log(
            "BASC Ape NFT = ",
            animal.apeMint.toBase58(),
            userApeTokenAccount.toBase58()
          );

          let retUserTiger = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            wallet.publicKey,
            [animal.tigerMint]
          );
          if (retUserTiger.instructions.length > 0)
            tx.add(retUserTiger.instructions[0]);
          let userTigerTokenAccount = retUserTiger.destinationAccounts[0];
          console.log(
            "BASC Tiger NFT = ",
            animal.tigerMint.toBase58(),
            userTigerTokenAccount.toBase58()
          );

          const [globalAuthority, bump] = await PublicKey.findProgramAddress(
            [Buffer.from(GLOBAL_AUTHORITY_SEED)],
            program.programId
          );

          let retApe = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            globalAuthority,
            [animal.apeMint]
          );
          console.log(
            "Dest Ape NFT Account = ",
            retApe.destinationAccounts[0].toBase58()
          );

          let retTiger = await getATokenAccountsNeedCreate(
            provider.connection,
            wallet.publicKey,
            globalAuthority,
            [animal.tigerMint]
          );

          console.log(
            "Dest Tiger NFT Account = ",
            retTiger.destinationAccounts[0].toBase58()
          );

          let userPoolKey = await PublicKey.createWithSeed(
            wallet.publicKey,
            "user-pair-pool",
            program.programId
          );

          let ret = await getATokenAccountsNeedCreate(
            provider?.connection,
            wallet.publicKey,
            wallet.publicKey,
            [REWARD_TOKEN_MINT]
          );
          console.log(
            "User Reward Account = ",
            ret.destinationAccounts[0].toBase58()
          );

          if (ret.instructions.length > 0) tx.add(ret.instructions[0]);

          let rewardVault = await getAssociatedTokenAccount(
            globalAuthority,
            REWARD_TOKEN_MINT
          );
          console.log("RewardVault: ", rewardVault.toBase58());

          tx.add(
            program.instruction.withdrawNftFromPairPool(bump, {
              accounts: {
                owner: wallet.publicKey,
                userPool: userPoolKey,
                globalAuthority,
                rewardVault,
                userApeTokenAccount,
                destApeNftTokenAccount: retApe.destinationAccounts[0],
                apeNftMint: animal.apeMint,
                destTigerNftTokenAccount: retTiger.destinationAccounts[0],
                userTigerTokenAccount,
                tigerNftMint: animal.tigerMint,
                userRewardAccount: ret.destinationAccounts[0],
                tokenProgram: TOKEN_PROGRAM_ID,
              },
              instructions: [],
              signers: [],
            })
          );

          transactions.push(tx);
          if (!bunchSending) break;
          else index++;
        }

        let { blockhash } = await provider.connection.getRecentBlockhash(
          "confirmed"
        );

        transactions.forEach((transaction) => {
          transaction.feePayer = wallet.publicKey as PublicKey;
          transaction.recentBlockhash = blockhash;
        });
        const signedTransactions = await wallet.signAllTransactions(
          transactions
        );

        let signatures = await Promise.all(
          signedTransactions.map(
            (transaction) =>
              provider.connection.sendRawTransaction(transaction.serialize(), {
                skipPreflight: true,
                maxRetries: 3,
                preflightCommitment: "confirmed",
              })
            // wallet.sendTransaction(transaction, provider.connection)
          )
        );
        await Promise.all(
          signatures.map((signature) =>
            provider.connection.confirmTransaction(signature, "finalized")
          )
        );
        toast.update(unStakeToast, {
          render: `${
            !bunchSending
              ? (animalData as PairAnimal).apeMetadata.name
              : `${(animalData as PairAnimal[]).length} NFTs`
          } has successfully Unstaked`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      } catch (err) {
        meaningfulErrorOutput(err, `Failed to Unstake!`, unStakeToast);
      } finally {
        unStakeToast = toast.loading(`Re-fetching data...`);
        await fetchAnimals();
        await fetchStakedAnimals();
        await fetchJungle();
        await fetchStakedAnimals();
        toast.update(unStakeToast, {
          render: `User pool updated!`,
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
      }
    },
    [provider, wallet, fetchStakedAnimals, fetchJungle, fetchAnimals]
  );

  // Fetches a staking account
  const fetchAnimal = useCallback(
    async (mint: PublicKey) => {
      if (!props.connection) return;

      const program = new anchor.Program(
        idl as anchor.Idl,
        programID,
        provider
      );
      const [animalAddress] = await PublicKey.findProgramAddress(
        [Buffer.from("animal"), mint.toBuffer()],
        programID
      );

      const metadataItem = constants.metadata.filter(
        (e) => e.mint === mint.toString()
      )[0];
      try {
        const fetchedAnimal = await program.account.animal.fetch(animalAddress);
        return {
          mint: mint,
          metadata: metadataItem.arweave,
          rank: metadataItem.rank,
          uriData: metadataItem.metadata,
          emissionsPerDay: fetchedAnimal.emissionsPerDay.toString(),
          faction: metadataItem.faction,
          lastClaim: new Date(fetchedAnimal.lastClaim.toNumber() * 1000),
        };
      } catch (err) {
        return {
          mint: mint,
          metadata: metadataItem.arweave,
          rank: metadataItem.rank,
          uriData: metadataItem.metadata,
          emissionsPerDay: metadataItem.emissionsPerDay,
          faction: metadataItem.faction,
        };
      }
    },
    [props.connection, provider]
  );


  const claimStakingRewards = useCallback(
    async (animal: Animal) => {
      if (!wallet || !wallet.publicKey || !provider) return;

      const claimToast = toast.loading("Claiming Earnings...");

      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      const [globalAuthority, bump] = await PublicKey.findProgramAddress(
        [Buffer.from(GLOBAL_AUTHORITY_SEED)],
        program.programId
      );
      console.log("globalAuthority =", globalAuthority.toBase58());

      let userPoolKey = await PublicKey.createWithSeed(
        wallet.publicKey,
        "user-pool",
        program.programId
      );

      try {
        // Create an reward account if the user does not have one
        let tx = new Transaction();
        let { instructions, destinationAccounts } =
          await getATokenAccountsNeedCreate(
            provider?.connection,
            wallet.publicKey,
            wallet.publicKey,
            [REWARD_TOKEN_MINT]
          );
        console.log(
          "User Reward Account = ",
          destinationAccounts[0].toBase58()
        );

        if (instructions.length > 0) tx.add(instructions[0]);

        let rewardVault = await getAssociatedTokenAccount(
          globalAuthority,
          REWARD_TOKEN_MINT
        );
        console.log("RewardVault: ", rewardVault.toBase58());

        tx.add(
          program.instruction.claimReward(bump, animal.mint, {
            accounts: {
              owner: wallet.publicKey,
              userPool: userPoolKey,
              globalAuthority,
              rewardVault,
              userRewardAccount: destinationAccounts[0],
              tokenProgram: TOKEN_PROGRAM_ID,
            },
            instructions: [],
            signers: [],
          })
        );

        let txId = await wallet.sendTransaction(tx, provider.connection);
        await provider.connection.confirmTransaction(txId, "confirmed");

        toast.update(claimToast, {
          render: "Claiming successful",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
        await provider.connection.confirmTransaction(txId, "finalized");

        // fetchAnimals();
        fetchStakedAnimals();
        // fetchUserAccount();
      } catch (err) {
        meaningfulErrorOutput(err, "Failed to Claim Earnings", claimToast);
      }
    },
    [
      // jungle,
      provider,
      // userAccount,
      wallet,
      // fetchAnimals,
      fetchStakedAnimals,
      // fetchUserAccount,
    ]
  );

  const claimPairStakingRewards = useCallback(
    async (animal: PairAnimal) => {
      if (!wallet || !wallet.publicKey || !provider) return;

      const claimToast = toast.loading("Claiming Earnings...");

      const program = new anchor.Program<BascStakingProgram>(
        BascStakingIdl,
        programID,
        provider
      );

      const [globalAuthority, bump] = await PublicKey.findProgramAddress(
        [Buffer.from(GLOBAL_AUTHORITY_SEED)],
        program.programId
      );
      console.log("globalAuthority =", globalAuthority.toBase58());

      let userPoolKey = await PublicKey.createWithSeed(
        wallet.publicKey,
        "user-pair-pool",
        program.programId
      );

      try {
        // Create an reward account if the user does not have one
        let tx = new Transaction();
        let { instructions, destinationAccounts } =
          await getATokenAccountsNeedCreate(
            provider?.connection,
            wallet.publicKey,
            wallet.publicKey,
            [REWARD_TOKEN_MINT]
          );
        console.log(
          "User Reward Account = ",
          destinationAccounts[0].toBase58()
        );

        if (instructions.length > 0) tx.add(instructions[0]);

        let rewardVault = await getAssociatedTokenAccount(
          globalAuthority,
          REWARD_TOKEN_MINT
        );
        console.log("RewardVault: ", rewardVault.toBase58());

        tx.add(
          program.instruction.claimPairReward(
            bump,
            animal.apeMint,
            animal.tigerMint,
            {
              accounts: {
                owner: wallet.publicKey,
                userPool: userPoolKey,
                globalAuthority,
                rewardVault,
                userRewardAccount: destinationAccounts[0],
                tokenProgram: TOKEN_PROGRAM_ID,
              },
              instructions: [],
              signers: [],
            }
          )
        );

        let txId = await wallet.sendTransaction(tx, provider.connection);
        await provider.connection.confirmTransaction(txId, "confirmed");

        toast.update(claimToast, {
          render: "Claiming successful",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 4000,
        });
        await provider.connection.confirmTransaction(txId, "finalized");

        // fetchAnimals();
        fetchStakedAnimals();
        // fetchUserAccount();
      } catch (err) {
        meaningfulErrorOutput(err, "Failed to Claim Earnings", claimToast);
      }
    },
    [
      // jungle,
      provider,
      // userAccount,
      wallet,
      // fetchAnimals,
      fetchStakedAnimals,
      // fetchUserAccount,
    ]
  );

  // claim all Staking Rewards
  const claimAllStakingRewards = useCallback(async () => {
    if (!stakedAnimals) return;
    if (!wallet || !wallet.publicKey || !provider) return;
    const feePayer = wallet.publicKey;

    const claimToast = toast.loading("Claiming All Earnings...");

    const program = new anchor.Program<BascStakingProgram>(
      BascStakingIdl,
      programID,
      provider
    );
    const [globalAuthority, bump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );
    console.log("globalAuthority =", globalAuthority.toBase58());

    let userPoolKey = await PublicKey.createWithSeed(
      wallet.publicKey,
      "user-pool",
      program.programId
    );
    const transaction = new Transaction({ feePayer });
    try {
      // Create an reward account if the user does not have one
      let { instructions, destinationAccounts } =
        await getATokenAccountsNeedCreate(
          provider?.connection,
          wallet.publicKey,
          wallet.publicKey,
          [REWARD_TOKEN_MINT]
        );
      console.log("User Reward Account = ", destinationAccounts[0].toBase58());

      if (instructions.length > 0) transaction.add(instructions[0]);

      let rewardVault = await getAssociatedTokenAccount(
        globalAuthority,
        REWARD_TOKEN_MINT
      );
      console.log("RewardVault: ", rewardVault.toBase58());

      transaction.add(
        program.instruction.claimReward(bump, null, {
          accounts: {
            owner: wallet.publicKey,
            userPool: userPoolKey,
            globalAuthority,
            rewardVault,
            userRewardAccount: destinationAccounts[0],
            tokenProgram: TOKEN_PROGRAM_ID,
          },
          instructions: [],
          signers: [],
        })
      );

      const signature = await wallet.sendTransaction(
        transaction,
        props.connection
      );

      await props.connection.confirmTransaction(signature, "confirmed");

      toast.update(claimToast, {
        render: "Claiming successful",
        type: "success",
        isLoading: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 4000,
      });
      await props.connection.confirmTransaction(signature, "finalized");

      // fetchUserAccount();
      // fetchAnimals();
      fetchStakedAnimals();
    } catch (err) {
      meaningfulErrorOutput(err, "Failed to Claim Earnings", claimToast);
    }
  }, [
    stakedAnimals,
    wallet,
    provider,
    avaliableStakedAnimals,
    props.connection,
    fetchStakedAnimals,
  ]);

  // claim all Staking Rewards
  const claimAllPairStakingRewards = useCallback(async () => {
    if (!stakedPairAnimals) return;
    if (!wallet || !wallet.publicKey || !provider) return;
    const feePayer = wallet.publicKey;

    const claimToast = toast.loading("Claiming All Pair Earnings...");

    const program = new anchor.Program<BascStakingProgram>(
      BascStakingIdl,
      programID,
      provider
    );
    const [globalAuthority, bump] = await PublicKey.findProgramAddress(
      [Buffer.from(GLOBAL_AUTHORITY_SEED)],
      program.programId
    );
    console.log("globalAuthority =", globalAuthority.toBase58());

    let userPoolKey = await PublicKey.createWithSeed(
      wallet.publicKey,
      "user-pair-pool",
      program.programId
    );
    const transaction = new Transaction({ feePayer });
    try {
      // Create an reward account if the user does not have one
      let { instructions, destinationAccounts } =
        await getATokenAccountsNeedCreate(
          provider?.connection,
          wallet.publicKey,
          wallet.publicKey,
          [REWARD_TOKEN_MINT]
        );
      console.log("User Reward Account = ", destinationAccounts[0].toBase58());

      if (instructions.length > 0) transaction.add(instructions[0]);

      let rewardVault = await getAssociatedTokenAccount(
        globalAuthority,
        REWARD_TOKEN_MINT
      );

      transaction.add(
        program.instruction.claimPairReward(bump, null, null, {
          accounts: {
            owner: wallet.publicKey,
            userPool: userPoolKey,
            globalAuthority,
            rewardVault,
            userRewardAccount: destinationAccounts[0],
            tokenProgram: TOKEN_PROGRAM_ID,
          },
          instructions: [],
          signers: [],
        })
      );

      const signature = await wallet.sendTransaction(
        transaction,
        props.connection
      );

      await props.connection.confirmTransaction(signature, "confirmed");

      toast.update(claimToast, {
        render: "Pair Claiming successful",
        type: "success",
        isLoading: false,
        closeOnClick: true,
        closeButton: true,
        autoClose: 4000,
      });
      await props.connection.confirmTransaction(signature, "finalized");

      // fetchUserAccount();
      // fetchAnimals();
      fetchStakedAnimals();
    } catch (err) {
      meaningfulErrorOutput(err, "Failed to Pair Claim Earnings", claimToast);
    }
  }, [
    stakedPairAnimals,
    wallet,
    provider,
    avaliableStakedPairAnimals,
    props.connection,
    fetchStakedAnimals,
  ]);

  const refreshAnimals = useCallback(async () => {
    // setAnimals([]);
    // setStakedAnimals([]);
    await fetchJungle();
    await fetchAnimals();
    await fetchStakedAnimals();
  }, [fetchJungle, fetchStakedAnimals, fetchAnimals]);

  const getTimestamp = useCallback(async () => {
    if (!props.connection) return 0;
    const slot = (await props.connection.getEpochInfo()).absoluteSlot;
    const timestamp = await props.connection.getBlockTime(slot);
    // console.log(Date.now() / 1000, '=>', timestamp);
    return timestamp ?? 0;
  }, [props.connection]);
  // useEffect(() => {
  //   refreshAnimals();
  // },[anchorWallet, refreshAnimals]);
 

  return (
    <StackContext.Provider
      value={{
        jungle,
        globalInfo,
        animals: animals || [],
        stakedAnimals: stakedAnimals || [],
        pairAnimals: pairAnimals || [],
        stakedPairAnimals: stakedPairAnimals || [],
        userAccount,
        getTimestamp,
        getRarityMultiplier,
        getPendingStakingRewards,
        getPendingPairStakingRewards,
        fetchAnimal,
        refreshAnimals,
        fetchUserAccount,
        createAccount,
        stakeAnimal,
        stakePairAnimal,
        unstakeAnimal,
        unstakePairAnimal,
        claimStakingRewards,
        claimPairStakingRewards,
        claimAllStakingRewards,
        claimAllPairStakingRewards,
        animalsStatus,
        stakedAnimalsStatus,
        avaliableStakedAnimals,
        setAvaliableStakedAnimals,
        avaliableStakedPairAnimals,
        setAvaliableStakedPairAnimals,
        refetchData
      }}
    >
      {props.children}

      <div>
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </StackContext.Provider>
  );
};

const meaningfulErrorOutput = (
  err: unknown,
  originMsg: string,
  toastObj: React.ReactText
) => {
  let msg = "";
  msg = " " + (err as any).message ?? "";

  const confirmErrIdx = msg.indexOf("confirmed");

  if (confirmErrIdx !== -1) {
    console.log("--->", msg.slice(confirmErrIdx + 66, confirmErrIdx + 66 + 88));
    msg = " Unknown Confirmation Result. Please Refresh to check result!";
  }
  console.log(originMsg + msg);
  toast.update(toastObj, {
    render: confirmErrIdx !== -1 ? "Warning!" + msg : originMsg + msg,
    type: confirmErrIdx !== -1 ? "warning" : "error",
    isLoading: false,
    closeOnClick: true,
    closeButton: true,
    autoClose: 4000,
  });
  console.error(err);

  if ((err as any).code === 4001) {
    console.log("==>User Reject");
    throw "User reject transaction";
  }
};

function stringify(value: any) {
  switch (typeof value) {
    case "string":
    case "object":
      return JSON.stringify(value);
    default:
      return String(value);
  }
}
